import mobileSearchField from "platform/shared/templates/mobile-search-field.hbs";
import mobileHeader from "platform/shared/templates/mobile-header.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Header",
    app.views.Base.extend({
      template: mobileHeader,

      partials: {
        "mobile.shared.mobile_search_field": mobileSearchField
      },

      searchFieldSelector: ".search-field-container",

      events: {
        "tap .on-close-search-results": "onCloseSearchResults",
        "click .on-close-search-results": "stopEvent",
        "tap .on-open-search-results": "showSearchResultsView",
        "click .on-open-search-results": "stopEvent",
        "tap .on-browse-topics": "showBrowseTopicsModal",
        "click .on-browse-topics": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};

        this.mode = options.mode || "stories";
        // data attribute is set on SSR
        this.selectedBrowseTopicKey = this.$("#browse-topics").data("topic");

        this.searchFieldView = new app.views.SearchField({
          mode: options.mode,
          query: options.query || "",
          isOnSearch: options.isOnSearch || false
        });

        _.each(this.partials, function(value, key) {
          Handlebars.registerPartial(key, value);
        });

        this.listenTo(app, "navbar:browse-label:set", this.setBrowseLabel);
        this.listenTo(app, "navbar:browse-label:reset", this.resetBrowseLabel);
        this.listenTo(
          app,
          "navbar:search-results:show",
          this.showSearchResultsView
        );
        this.listenTo(
          app,
          "navbar:search-results:hide",
          this.hideSearchResultsView
        );
      },

      setElement: function(el) {
        Monaco.View.prototype.setElement.apply(this, arguments);

        this.setSubElements(this.searchFieldView);
      },

      setSubElements: function(subview) {
        var classes,
          sel = "";
        if (subview) {
          classes = subview.className.trim().split(" ");
          _.each(classes, function(text) {
            sel += "." + text;
          });
          subview.setElement(this.$el.find(sel.trim()));
        }
      },

      render: function() {
        var currentUser = wattpad.utils.currentUser(),
          data = {};

        data["current-user"] = currentUser.authenticated()
          ? currentUser.toJSON()
          : null;
        data.currentUrl = wattpad.utils.getNextUrl(window.location.href);
        data.downloadAppUrl = wattpad.utils.getAppUrl();
        data.testGroups = wattpad.testGroups;

        if (this.searchFieldView) {
          _.extend(data, this.searchFieldView.getData());
        }

        this.$el.html(this.template(data));
        return this;
      },

      onNavigate: function(evt) {
        this.stopEvent(evt);

        var $target = $(evt.currentTarget);
        this.$("a.on-navigate").removeClass("active");

        // Highlight selected icon for the logged in navbar
        if (wattpad.utils.currentUser().authenticated()) {
          $target.addClass("active");
        }

        app.router.navigate($target.attr("href"), { trigger: true });
      },

      onCloseSearchResults: function() {
        this.searchFieldView.hideDropdownResults();
      },

      showSearchResultsView: function(evt) {
        if (evt) {
          wattpad.utils.stopEvent(evt);
        }

        if (this.isOnSearch) {
          return;
        }

        this.$("#anonymous-avatar").addClass("hidden");
        this.$("#search-results-close").removeClass("hidden");

        if (!$("#generic-modal").is(":visible")) {
          this.$(".on-open-search-results").addClass("hidden");
          this.$("#browse-topics").addClass("hidden");
          this.$(".search-field-container").removeClass("hidden");
          $("#header-container").addClass("search-view");

          $("#generic-modal .modal-body").empty();
          $("#generic-modal")
            .modal("show")
            .one("hidden.bs.modal", this.hideSearchResultsView.bind(this));
        }
      },

      hideSearchResultsView: function() {
        if ($("#generic-modal").is(":visible")) {
          $("#generic-modal").modal("hide");
        } else {
          $("#header-container").removeClass("search-view");
          this.$(".search-field-container").addClass("hidden");
          this.$("#browse-topics").removeClass("hidden");
          this.$(".on-open-search-results").removeClass("hidden");
        }

        this.$("#search-results-close").addClass("hidden");
        this.$("#anonymous-avatar").removeClass("hidden");
      },

      setBrowseLabel: function(label, topicKey) {
        this.$("#browse-topics .name").text(label);
        this.selectedBrowseTopicKey = topicKey;
      },

      resetBrowseLabel: function() {
        this.$("#browse-topics .name").text(wattpad.utils.trans("Browse"));
        this.selectedBrowseTopicKey = null;
      },

      showBrowseTopicsModal: function() {
        var view = new app.views.BrowseTopics({
          isModal: true,
          selectedTopic: this.selectedBrowseTopicKey
        });
        view.render();

        this.listenToOnce(view, "render:done", function() {
          $("#generic-modal .modal-body").html(view.$el);
          $("#generic-modal").modal("show");
        });
      }
    })
  );
})(window, _, wattpad, window.app);

import browseTopics from "platform/browse/templates/browse-topics.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "BrowseTopics",
    app.views.Base.extend({
      className: "browse-topics",
      template: browseTopics,

      events: {
        "tap .on-topic": "onTopicClick"
      },

      initialize: function(options) {
        options = options || {};
        this.selectedTopic = options.selectedTopic;
        this.isModal = options.isModal || false;
        this.collection = new app.collections.BrowseTopics();
      },

      render: function() {
        var self = this;

        Promise.resolve(this.collection.fetch()).then(function() {
          var templateData = {
            isModal: self.isModal,
            headerName: utils.trans("Browse"),
            options: self.formatTopicsObject(self.collection.toJSON()),
            testGroups: wattpad.testGroups
          };

          self.$el.html(self.template(templateData));
          self.trigger("render:done");
        });

        return this;
      },

      formatTopicsObject: function(topics) {
        var newTopicsObject = [],
          self = this;
        var urlPath = "/stories/";

        _.each(topics, function(topic) {
          newTopicsObject.push({
            key: topic.name,
            name: topic.topic,
            browseURL: topic.browseURL ?? "",
            url: topic.name === self.selectedTopic ? "" : urlPath + topic.name
          });
        });

        return newTopicsObject;
      },

      onTopicClick: function(evt) {
        var sel_topic = $(evt.target).text();
        window.te.push("event", "browse", "topic", null, "click", {
          topic: sel_topic
        });
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"mobile-cta\">\n    <div class=\"prompt\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.cta : depth0)) != null ? stack1.prompt : stack1), depth0))
    + "</div>\n    <a href=\""
    + alias2(((helper = (helper = helpers.branchLink || (depth0 != null ? depth0.branchLink : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"branchLink","hash":{},"data":data}) : helper)))
    + "\"\n       class=\"btn btn-teal btn-block\">\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.cta : depth0)) != null ? stack1.button : stack1), depth0))
    + "\n    </a>\n</div>\n";
},"useData":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "logged-in";
},"3":function(container,depth0,helpers,partials,data) {
    return "logged-out";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "  <a href=\"/home\" aria-controls=\"home\">\n    <div class=\"icon-container\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"home",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n  </a>\n  <a href=\"/library\" aria-controls=\"library\">\n    <div class=\"icon-container\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"library",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n  </a>\n  <a href=\"/search\" aria-controls=\"search\" class=\"on-navigate\">\n    <div class=\"icon-container\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"search",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n  </a>\n  <a href=\"/notifications\" aria-controls=\"notifications\" class=\"notifications-icon\">\n    <div class=\"icon-container\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.notifications : stack1)) != null ? stack1.unread : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"notifications",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n  </a>\n  <a href=\"/menu\" aria-controls=\"menu\">\n    <div aria-controls=\"settings\" class=\"avatar avatar-xs2\">\n      <img src=\""
    + container.escapeExpression((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.avatar : stack1),30,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </div>\n  </a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <span id=\"notifications-menu-badge\">\n            "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.notifications : stack1)) != null ? stack1.unread : stack1),{"name":"count","hash":{},"data":data}))
    + "\n            </span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", buffer = 
  "  <a href=\"/home\" class=\"logo\">\n    "
    + ((stack1 = (helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/logos/download-app-icon.png",{"name":"connectImage","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </a>\n\n    <div id=\"browse-topics\" class=\"on-browse-topics\" ";
  stack1 = ((helper = (helper = helpers.topic || (depth0 != null ? depth0.topic : depth0)) != null ? helper : alias2),(options={"name":"topic","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.topic) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n      <span class=\"name\">\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.browseLabel : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </span>\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-caret",15,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n\n    <a href=\"/search\" aria-controls=\"search\" class=\"on-open-search-results\" aria-label=\"Search\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"search",22,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </a>\n\n    <div class=\"search-field-container hidden\">\n      <div class=\"search-field-view\">\n"
    + ((stack1 = container.invokePartial(partials["mobile.shared.mobile_search_field"],depth0,{"name":"mobile.shared.mobile_search_field","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n\n  <a href=\"/login?nexturl="
    + container.escapeExpression(((helper = (helper = helpers.currentUrl || (depth0 != null ? depth0.currentUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentUrl","hash":{},"data":data}) : helper)))
    + "\" id=\"anonymous-avatar\" class=\"on-navigate\" rel=\"nofollow\" aria-label=\"Profile\">\n    <div class=\"avatar\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"profile",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n  </a>\n\n  <div id=\"search-results-close\" class=\"on-close-search-results hidden\">\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",25,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "data-topic=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.browseLabel || (depth0 != null ? depth0.browseLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"browseLabel","hash":{},"data":data}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Browse",{"name":"trans","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = 
  "<div id=\"app-header\" class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = helpers["current-user"] || (depth0 != null ? depth0["current-user"] : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"current-user","hash":{},"fn":container.noop,"inverse":container.program(8, data, 0),"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers["current-user"]) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n\n\n";
},"usePartial":true,"useData":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal-dialog modal-sm\">\n  <div class=\"modal-content\">\n\n    <header class=\"modal-header\">\n      <h3 class=\"modal-title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Settings",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n\n      <div class=\"close top-right\" data-dismiss=\"modal\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </header>\n\n    <div class=\"modal-body\">\n      <div class=\"info\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"text\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"To change your profile photo, background image, and description, login to Wattpad on desktop.",{"name":"trans","hash":{},"data":data}))
    + "</div>\n      </div>\n\n      <ul class=\"options\">\n        <li id=\"on-logout\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Log Out",{"name":"trans","hash":{},"data":data}))
    + "</li>\n      </ul>\n    </div>\n\n  </div>\n</div>\n";
},"useData":true});
import discoverModuleStoriesSmall from "core/templates/item-views/discover-module-stories-small.hbs";
import readingListItem from "platform/shared/templates/item/reading-list-item.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "ReadingListItem",
    app.views.Base.extend({
      className: "reading-list",
      template: readingListItem,

      events: {
        "tap    .send-list-event": "sendListClickTrackingEvent",
        "click  .send-list-event": "stopEvent",

        "tap    .story-item": "sendStoryClickTrackingEvent",
        "click  .story-item": "stopEvent"
      },

      initialize: function() {
        var limit = 5; // Show max 5 stories per reading list
        this.collection = this.model.stories(limit);

        var self = this;
        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          self.render();
        });

        Handlebars.registerPartial(
          "core.item_views.discover_module_stories_small",
          discoverModuleStoriesSmall
        );
      },

      render: function() {
        this.$el.html(this.template(this.formatViewData()));
        return this;
      },

      formatViewData: function() {
        var data = this.model.toJSON();
        _.extend(data, { storyItems: this.collection.toJSON().splice(0, 5) });

        return data;
      },

      sendListClickTrackingEvent: function() {
        window.te.push("event", "profile", "reading_list", "list", "click", {
          reading_listid: this.collection.listId,
          username: this.model.get("user").name
        });
      },

      sendStoryClickTrackingEvent: function(evt) {
        window.te.push("event", "profile", "reading_list", "story", "click", {
          reading_listid: this.collection.listId,
          storyid: $(evt.currentTarget).data("story-id"),
          username: this.model.get("user").name
        });
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"panel\">\n  <hr />\n\n  <div id=\"following-modal\"></div>\n\n  <div class=\"section-header\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Following",{"name":"trans","hash":{},"data":data}))
    + "</div>\n\n  <div class=\"collection\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.users : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowingBreak1 : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowingBreak2 : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowingBreak3 : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"user hide\">\n      <a class=\"on-user-item on-navigate avatar\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n        <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),94,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n      </a>\n\n      <div class=\"name\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      <div class=\"num-followers\">"
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Follower","%s Followers",(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"count","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "</div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"on-following break1 hide\">+"
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.numFollowingBreak1 : depth0),{"name":"count","hash":{},"data":data}))
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"on-following break2 hide\">+"
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.numFollowingBreak2 : depth0),{"name":"count","hash":{},"data":data}))
    + "</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"on-following break3 hide\">+"
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.numFollowingBreak3 : depth0),{"name":"count","hash":{},"data":data}))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.users : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
(function(window, _, $, wattpad, app) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});

  app.router.on(
    "route:userProfile",
    app.router.filter(["getTranslations", "getCategories"], function(
      username,
      section,
      activityId,
      params
    ) {
      // WEB-7096 If param field contains redirectFrom, it means we need to show the toast
      if (
        typeof params === "object" &&
        (params["redirectfrom"] || params["redirectFrom"])
      ) {
        var toast = new app.views.ErrorToast(
          {
            message:
              wattpad.utils.trans(
                "We're sorry! The page you requested no longer exists."
              ) +
              " " +
              wattpad.utils.trans("So we have brought you to a related page.")
          },
          { type: "dismissable" }
        );
        toast.render();
      }

      // default to about if no section is informed
      section = section || "about";

      // Model
      var model = new app.models.User({ username: username });

      // TODO: this code waits for data to be in before the view is
      //       created and shown to the user - unnecessary delay

      $.when(model.loaded())
        .done(function() {
          var isPrivateToMe = !model.has("description"); // definitely requested, but not given === private

          let isMuted = model?.get("safety")?.isMuted;
          let isBlocked = model?.get("safety")?.isBlocked;

          // clear extraneous url
          if (isPrivateToMe) {
            app.router.navigate("/user/" + username, {
              trigger: false,
              replace: true
            });
          }

          const noIndex =
            wattpad.utils.isUnsafe(model.get("name")) ||
            wattpad.utils.isUnsafe(model.get("username")) ||
            wattpad.utils.isUnsafe(model.get("description"));

          if (isBlocked) {
            var blockedView = new app.views.RestrictedProfile({
              profileType: "block",
              model: model,
              isMobile: app.get("device").is.mobile
            });

            app.transitionTo(blockedView, {
              username: model.get("username"),
              hasHeader: true,
              hasFooter: true,
              noIndex: noIndex
            });
          } else if (isMuted) {
            var mutedView = new app.views.RestrictedProfile({
              profileType: "mute",
              model: model,
              isMobile: app.get("device").is.mobile
            });

            app.transitionTo(mutedView, {
              username: model.get("username"),
              hasHeader: true,
              hasFooter: true,
              noIndex: noIndex
            });
          } else {
            var profileView = new app.views.UserProfile({
              model: model,
              section: isPrivateToMe ? "about" : section,
              newestActivityId: activityId,
              isPrivateToMe: isPrivateToMe
            });

            app.transitionTo(profileView, {
              username: model.get("username"),
              hasFooter: true,
              hasHeader: true,
              noIndex: noIndex,
              footerOptions: {
                appUrl: wattpad.utils.getAppLink("user-profile", model)
              }
            });
          }

          window.te.push("event", "app", "page", null, "view", {
            page: "profile",
            username: username
          });
        })
        .fail(function(err) {
          app.router.trigger("route:error-404", "user");
        });
    })
  );
})(window, _, jQuery, wattpad, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<header class=\"modal-header\">\n  <h1 class=\"modal-title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h1>\n\n  <div class=\"close\" data-dismiss=\"modal\">\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",17,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</header>\n\n<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n    <div class=\"modal-body\">\n\n      <div id=\"user-row-items\" class=\"collection row\"></div>\n\n      <div class=\"show-more center-text"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMore : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n        <button class=\"btn btn-grey on-showmore\" role=\"button\">\n          <span class=\"show-more-message\">\n            <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-down-small",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n          </span>\n          <span class=\"loading-message hidden\">\n            "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n            <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading",{"name":"trans","hash":{},"data":data}))
    + " ...</span>\n          </span>\n        </button>\n      </div> \n    </div>\n\n  </div>\n</div>\n";
},"useData":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "          <li><a href=\"https://www.wattpad.com/privacy/settings\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Do Not Sell My Personal Information",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option data-code=\""
    + alias4(((helper = (helper = helpers.locale || (depth0 != null ? depth0.locale : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locale","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<footer id=\"auth-footer\">\n  <div class=\"container auth-footer-wrapper\">\n    <div class=\"auth-socials\">\n      <a href=\"https://www.facebook.com/wattpad\" rel=\"nofollow\"  target=\"_blank\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"social-media/facebook.svg","Facebook",{"name":"connectImage","hash":{"klass":"footer-facebook"},"data":data}))
    + "\n      </a>\n      <a href=\"https://twitter.com/wattpad\" rel=\"nofollow\"  target=\"_blank\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"social-media/twitter.svg","Twitter",{"name":"connectImage","hash":{"klass":"footer-twitter"},"data":data}))
    + "\n      </a>\n      <a href=\"https://instagram.com/wattpad/\" rel=\"nofollow\"  target=\"_blank\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"social-media/instagram.svg","Instagram",{"name":"connectImage","hash":{"klass":"footer-instagram"},"data":data}))
    + "\n      </a>\n      <a href=\"https://www.youtube.com/user/wattpad\" rel=\"nofollow\"  target=\"_blank\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"social-media/youtube.svg","Youtube",{"name":"connectImage","hash":{"klass":"footer-youtube"},"data":data}))
    + "\n      </a>\n      <a href=\"https://www.tiktok.com/@wattpad\" rel=\"nofollow\"  target=\"_blank\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"social-media/tiktok.svg","TikTok",{"name":"connectImage","hash":{"klass":"footer-tiktok"},"data":data}))
    + "\n      </a>\n    </div>\n    <div class=\"auth-row row\">\n      <div class=\"auth-links\">\n        <h5>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Links",{"name":"trans","hash":{},"data":data}))
    + "</h5>\n        <ul>\n            <li><a href=\"/user/wattpadoriginals\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Originals",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Originals",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a class=\"on-premium\" href=\"/premium\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Try Premium",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Try Premium",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"/getmobile\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get the App",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get the App",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://creators.wattpad.com/\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writers",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writers",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <hr />\n            <li><a href=\"https://brands.wattpad.com\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Brand Partnerships",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Brand Partnerships",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://company.wattpad.com/jobs\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Jobs",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Jobs",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://company.wattpad.com/press\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Press",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Press",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <hr />\n            <li><a href=\"/terms\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Terms",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Terms",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"/privacy\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://support.wattpad.com\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"CAN_VIEW_CCPA",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n      <div class=\"spacer\"></div>\n      <div  class=\"language-container\">\n        <h5>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Site Language",{"name":"trans","hash":{},"data":data}))
    + "</h5>\n        <select id=\"language-options\" class=\"pull-right\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.translatedLangs : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div> \n      <p class=\"legal\">&copy; "
    + alias3(((helper = (helper = helpers.currentYear || (depth0 != null ? depth0.currentYear : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"currentYear","hash":{},"data":data}) : helper)))
    + " Wattpad</p>\n</div>\n  </div>\n</footer>\n";
},"useData":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "  <section id=\"profile-works\">\n    "
    + ((stack1 = ((helper = (helper = helpers.worksContent || (depth0 != null ? depth0.worksContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"worksContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n  </section>\n\n  <section id=\"user-following-section\">\n    "
    + ((stack1 = ((helper = (helper = helpers.followingSectionContent || (depth0 != null ? depth0.followingSectionContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"followingSectionContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n  </section>\n\n  <section id=\"profile-share-links\">\n    <div class=\"panel\">\n      <hr />\n\n      <div class=\"section-header\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share profile",{"name":"trans","hash":{},"data":data}))
    + "</div>\n      "
    + ((stack1 = ((helper = (helper = helpers.socialShareSection || (depth0 != null ? depth0.socialShareSection : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialShareSection","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n  </section>\n\n  <section id=\"profile-pinned-item\">\n  	"
    + ((stack1 = ((helper = (helper = helpers.pinnedContent || (depth0 != null ? depth0.pinnedContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pinnedContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n  </section>\n\n  <section id=\"profile-lists\">\n    "
    + ((stack1 = ((helper = (helper = helpers.listsContent || (depth0 != null ? depth0.listsContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"listsContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n  </section>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<section class=\"user-description panel\">\n  "
    + ((stack1 = ((helper = (helper = helpers.aboutContent || (depth0 != null ? depth0.aboutContent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"aboutContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n</section>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.renderAll : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
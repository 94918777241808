import { MODAL_TYPE } from "../../../../components/shared-components/modals/constants";

/*
 * DefaultTransition
 * - used as the transition if none is specified
 * - used as the base class of all other transitions (except for BaseTransition)
 * - empty the container and hides the address bar unless told otherwise via options
 */
(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "DefaultTransition",
    app.transitions.BaseTransition.extend({
      containerSelector: "#app-container",
      footerSelector: "#footer-container",
      interstitialSelector: "#interstitial-container",
      getAppSelector: "#bottom-banner-container",

      start: function(fromView, toView, options) {
        options = options || {};
        this.headerSelector = "#header-container";

        // SSR load
        if (!fromView) {
          this._canonicalURL(options); // clean up URL
          this.setupAnnouncements();
        } else {
          // CSR load
          this._pageView();
          // Set the page title ( don't need to set it on SSR )
          if (options.pageTitle && options.pageTitle.length > 0) {
            wattpad.utils.setTitle(options.pageTitle);
          }
        }

        // Handle noindex meta tag on CSR between pages allow/disallow crawler indexing
        if (
          fromView &&
          options.noIndex &&
          $("meta[content=noindex]").length === 0
        ) {
          $("head").append("<meta name=robots content=noindex />");
        }
        if (fromView && !options.noIndex) {
          $("meta[name='robots']").remove();
        }

        //walk the DOM for modals; close them before continuing
        if ($("body").hasClass("modal-open")) {
          var self = this;
          $(".modal.in").one("hidden.bs.modal", function() {
            self.changeViews(fromView, toView, options);
          });
          $(".modal.in").modal("hide");
        } else {
          this.changeViews(fromView, toView, options);
        }
        // Setup get app view (bottom banner)
        this.setupGetAppView(toView);

        //manipulate header
        if (options.hasHeader) {
          this.showHeader(!fromView, toView);
        } else {
          this.hideHeader();
        }
        //manipulate footer
        if (options.hasFooter) {
          this.showFooter(!fromView, options.footerOptions);
        } else {
          this.hideFooter();
        }

        if (wattpad.testGroups.SENTIMENT_SURVEY) {
          $("#sg-popup").css("display", "none");
        }

        wattpad.utils.hideAddressBar();

        this.scrollTo(window, fromView);

        var currentUser = wattpad.utils.currentUser();
        // has_accepted_latest_tos could be null, so we check explicitly for false
        if (
          currentUser.get("has_accepted_latest_tos") === false &&
          !currentUser.get("has_seen_latest_tos_notice")
        ) {
          this.showUpdatedPolicyNoticeModal();
        }

        return toView;
      },

      changeViews: function(fromView, toView, options) {
        var emptyContainer = false;
        // case: refresh
        // TODO: Investigate whether this is ever useful; if we are re-rendering the same view, then what's the point of this?
        if (fromView && toView.el === fromView.el) {
          fromView.remove();
          toView.render(options);
        }

        // case: transition A -> B
        else if (fromView) {
          toView.render(options);
          fromView.remove();

          // case: render first page
        } else {
          options.isSSR = true;
          toView.render(options);
        }

        // empty the container by default unless told otherwise
        if (options.emptyContainer === void 0 || options.emptyContainer) {
          emptyContainer = true;
        }

        // append the content of the main view to the DOM
        this.injectDOM(
          fromView,
          toView,
          this.containerSelector,
          emptyContainer
        );

        if (options.viewChangedCallback !== undefined) {
          options.viewChangedCallback();
        }
        this.registerModals();
      },

      hideHeader: function() {
        var $header = $(this.headerSelector);
        $header.hide();
      },

      showHeader: function(isFirstLoad, toView) {
        var $header = $(this.headerSelector),
          header = app.get("header-obj");

        if (_.isEmpty(header)) {
          header = new app.views.Header({ el: this.headerSelector });
        }

        if (header.searchFieldView) {
          header.isOnSearch = false;
          header.searchFieldView.isOnSearch = false;
        }

        if ($header.children().length === 0) {
          $header.append(header.render());
        } else if (isFirstLoad) {
          header.setElement($header[0]);
        } else {
          $header.find("#search-query").val("");
        }

        // Don't reset the navbar dropdown label if navigating to app.views.Browse
        if (toView.el.id !== "browse-container") {
          app.trigger("navbar:browse-label:reset");
        }

        $header.show();
        app.set("header-obj", header);
      },

      hideFooter: function() {
        var $footer = $(this.footerSelector);
        $footer.hide();
      },

      showFooter: function(isFirstLoad, options) {
        var $footer = $(this.footerSelector);
        var footer =
          app.get("footer-obj") ||
          new app.views.Footer({ el: this.footerSelector });

        if (options) {
          footer.setOptions(options);
        }

        // if firstLoad but element exists, we are loading from SSR
        if (isFirstLoad) {
          footer.setElement($footer[0]);

          // append the footer to the DOM
        } else {
          $footer.empty().append(footer.render().$el);
        }

        $footer.show();
        app.set("footer-obj", footer);
      },

      setupAnnouncements: function() {
        // Bind announcements
        if ($("#announcements").children().length > 0) {
          _.each($("#announcements").children(), function(child) {
            var $child = $(child);
            if ($child.hasClass("announcement")) {
              if ($child.hasClass("verify-email")) {
                new app.views.VerifyEmail().setElement(child);
              } else if ($child.hasClass("email-verified-success")) {
                new app.views.EmailVerifiedSuccess().setElement(child);
              } else if ($child.hasClass("spielbergo")) {
                new app.views.SpielbergoAnnouncment().setElement(child);
              } else {
                new app.views.Announcement().setElement(child);
              }
            }
          });
        }
      },

      scrollTo: function(window, fromView) {
        if (fromView) {
          if (!fromView.shouldNotScrollToTop) {
            window.scrollTo(0, 0);
          } else {
            fromView.shouldNotScrollToTop = void 0;
          }
        }
      },

      /**
       *  This function is only called on first load of the DOM.
       */
      showInterstitial: function(view) {
        var device = app.get("device");

        // Only should be shown to logged-out users that haven't seen it before
        if (
          wattpad.user !== null ||
          wattpad.utils.getCookie("saw_intrstl") === "1" ||
          (device && device.isCrawler())
        ) {
          return;
        }

        // Don't show interstitial to webviews
        if (device.isWebView()) {
          return;
        }

        // Cookie the user
        wattpad.utils.setCookie("saw_intrstl", "1", 3, true);

        // Render the interstitial
        var $interstitial = $(this.interstitialSelector);
        var interstitial = new app.views.DownloadInterstitial({
          el: this.interstitialSelector
        });
        interstitial.setOptions({ appUrl: view.getDeepLink() });
        $interstitial.append(interstitial.render());
      },

      /** ARC-230:
       *  Will be modifying this to restrict experiment variation access to anon users
       *  that return several times but never convert to signing up.
       *  These users are segmented into "visited 5+ times" then "visited 10+ times"
       */
      showForcedSignupModal: function() {
        var view = new app.views.SignUpPrompt({
          model: new app.models.Authsignup(),
          formType: "navbar_modal",
          form: "signup",
          forcedSignup: true
        });

        $("#generic-modal .modal-body").html(view.render().$el);
        $("#generic-modal .signup-prompt").addClass("forced-signup-prompt");
        $("#generic-modal").modal({ backdrop: "static", keyboard: false });
        $("#generic-modal").one("shown.bs.modal", function() {
          $(this)
            .find("[autofocus]")
            .focus();
        });
      },

      determineBottomBanner: function(view) {
        var $bottomBannerContainer = $(this.getAppSelector),
          showInCurrentView = true;

        if (
          view instanceof app.views.RetargetingLanding ||
          view instanceof app.views.GetMobile ||
          view instanceof app.views.Authentication ||
          view instanceof app.views.Landing ||
          view instanceof app.views.ValidationSuccess ||
          view instanceof app.views.OauthAuthentication ||
          view instanceof app.views.FacebookAuthentication
        ) {
          showInCurrentView = false;
        }

        return (
          $bottomBannerContainer.length &&
          !wattpad.utils.currentUser().authenticated() &&
          showInCurrentView
        );
      },

      setupGetAppView: function(view) {
        var $bottomBannerContainer = $(this.getAppSelector),
          bottomBannerView = app.get("BottomBanner"),
          showBottomBanner = this.determineBottomBanner(view);

        if (bottomBannerView) {
          bottomBannerView.remove();
        }

        if (showBottomBanner) {
          bottomBannerView = new app.views.BottomBanner({
            showBottomBanner: true,
            fatBanner: true,
            view: view
          });
          app.set("BottomBanner", bottomBannerView);
          if (view.getDeepLink) {
            bottomBannerView.setDeepLink(
              view.getDeepLink(),
              view.getDeepLinkPage()
            );
          }
          $bottomBannerContainer.empty().append(bottomBannerView.$el);
        }

        const isForcedSignupExemptView = wattpad.utils.forcedSignupHelper.isForcedSignupExemptView(
          view
        );

        var loggedOutSessionCount = parseInt(
          wattpad.utils.getCookie("fs__exp")
        );
        const isForcedSignupSession = wattpad.utils.forcedSignupHelper.isForcedSignupSession(
          loggedOutSessionCount
        );

        if (
          !wattpad.user &&
          isForcedSignupSession &&
          !isForcedSignupExemptView
        ) {
          // Need to close one-tap if user is navigating to a page with the one-tap
          // modal already visible.
          window?.google?.accounts?.id?.cancel();
          this.showForcedSignupModal();
        }
      },

      showUpdatedPolicyNoticeModal: function() {
        var body =
          "We’re updating our Terms of Service and Privacy Policy, effective May 25th, 2018." +
          " By continuing to use Wattpad after May 25th, you’re agreeing to these new " +
          "<a href='/terms' target='_blank'>Terms</a> and <a href='/privacy' target='_blank'>Privacy Policy</a>.";

        var updatedPolicyNotice = new app.views.UpdatedPolicyNotice({
          model: wattpad.utils.currentUser(),
          title: "Updating our Terms",
          body: body
        });

        updatedPolicyNotice.showModal();
      },

      registerModals: function() {
        const bootstrapModals = document.getElementsByClassName("modal");
        for (let i = 0; i < bootstrapModals.length; i++) {
          const modal = bootstrapModals.item(i);
          if (!modal.id) return;

          window.wattpad.utils.registerModal(modal.id, MODAL_TYPE.BS_MODAL, {
            isOpen: () => $(`#${modal.id}`).hasClass("in"),
            show: () => {
              $(`#${modal.id}`).modal("show");
            },
            hide: () => {
              $(`#${modal.id}`).modal("hide");
            }
          });
        }
      }
    })
  );
})(window, Monaco, wattpad, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\n  <a class=\"on-open-in-app\" href=\""
    + alias3(((helper = (helper = helpers.openAppUrl || (depth0 != null ? depth0.openAppUrl : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"openAppUrl","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-xs-10\"><h6>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Open in the Wattpad App",{"name":"trans","hash":{},"data":data}))
    + "</h6></div>\n    <div class=\"col-xs-2\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right-small",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n  </a>\n</div>\n<iframe style=\"display:none\" height=\"0\" width=\"0\" id=\"deeplink-loader\"></iframe>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "       <li><a href=\"/privacy/settings\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Do Not Sell My Personal Information",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Do Not Sell My Personal Information",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row\">\n  <a class=\"on-view-full-site\" href=\""
    + alias4(((helper = (helper = helpers.desktopUrl || (depth0 != null ? depth0.desktopUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desktopUrl","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-xs-10\"><h6>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View Full Site",{"name":"trans","hash":{},"data":data}))
    + "</h6></div>\n    <div class=\"col-xs-2\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right-small",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n  </a>\n</div>\n<div class=\"row\">\n  <div class=\"col-xs-10\"><h6><a href=\"/settings/language?jq=true\" class=\"on-language\" title=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language",{"name":"trans","hash":{},"data":data}))
    + "\" rel=\"nofollow\" aria-label=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language",{"name":"trans","hash":{},"data":data}))
    + "</a></h6></div>\n  <div class=\"col-xs-2\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right-small",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showOpenApp : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"row get-app\">\n  <a class=\"on-download-app\" href=\""
    + alias4(((helper = (helper = helpers.downloadAppUrl || (depth0 != null ? depth0.downloadAppUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"downloadAppUrl","hash":{},"data":data}) : helper)))
    + "\">\n    <h6>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get the App",{"name":"trans","hash":{},"data":data}))
    + "</h6>\n  </a>\n</div>\n<footer>\n  <ul>\n     <li><a href=\"/terms\" rel=\"nofollow\" aria-label=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Terms",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Terms",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n     <li><a href=\"/privacy\" rel=\"nofollow\" aria-label=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"CAN_VIEW_CCPA",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "     <li><a href=\"https://policies.wattpad.com/payment\" aria-label=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Payment Policy",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Payment Policy",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n     <li><a href=\"https://support.wattpad.com\" aria-label=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n  </ul>\n  <p>&copy; "
    + alias4(((helper = (helper = helpers.currentYear || (depth0 != null ? depth0.currentYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentYear","hash":{},"data":data}) : helper)))
    + " - Wattpad</p>\n</footer>\n";
},"useData":true});
import newPinnedItem from "platform/profile/templates/new-pinned-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Profile Sub-view -- About Me
   * @class ProfileAbout
   * @constructor
   */
  app.add(
    "NewPinnedItem",
    Monaco.View.extend({
      // Properties
      // ----------
      className: "pinned-item panel container",
      template: newPinnedItem,

      // Methods
      // -------

      render: function() {
        var pinnedItem = this.model.toJSON();

        this.$el.html(this.template({ pinnedItem: pinnedItem }));
        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);

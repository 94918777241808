var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, buffer = 
  "     --><div class=\"reading-list-grid-panel reading-list-card\">\n            <div class=\"content\">\n                <a href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"><h5>"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),46,{"name":"simpleShorten","hash":{},"data":data}))
    + "</h5><!--\n";
  stack1 = ((helper = (helper = helpers.sample_covers || (depth0 != null ? depth0.sample_covers : depth0)) != null ? helper : alias2),(options={"name":"sample_covers","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.sample_covers) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                --></a>\n            </div>\n            <footer>\n                <a href=\"/user/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"user-link\">\n                    <img src=\""
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.avatar : stack1), depth0))
    + "\" class=\"avatar avatar-md\">\n                    <h5>"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</h5>\n                    <div class=\"controls\">\n                        <button class='btn btn-fan btn-follow on-follow' data-target='"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "'>\n                            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",22,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                        </button>\n                    </div>\n                </a>\n            </footer>\n        </div><!--\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                    --><div class=\"sample-cover\">\n                    <img src=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\" class=\"cover cover-sm\">\n                    </div><!--\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div  class=\"discover-reading_lists_with_users-mobile\" data-type=\"discover-reading_lists_with_users\" data-url=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" data-cache=\""
    + alias4(((helper = (helper = helpers.cache || (depth0 != null ? depth0.cache : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cache","hash":{},"data":data}) : helper)))
    + "\">\n    <h2 class=\"story-grid-title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n    <h3><small>"
    + alias4(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</small></h3>\n\n    <div class=\"reading-list-grid\"><!--\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.lists : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    --></div>\n</div>\n";
},"useData":true});
import userMenu from "core/templates/components/user-menu.hbs";
(function(window, _, wattpad, app, utils) {
  "use strict";

  app.add(
    "UserMenu",
    app.views.Base.extend({
      template: userMenu,

      events: {
        "tap .on-logout": "onLogout",
        "click .on-logout": "stopEvent"
      },

      render: function() {
        var templateData = {
          username: utils.currentUser().get("username")
        };

        this.$el.html(this.template(templateData));
        return this;
      },

      onLogout: function() {
        wattpad.utils.destroyCookie("hc");
        window.localStorage.clear();
        utils.redirectToServer("/logout");
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.firstPartId : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "            <p class=\"upper teal\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Published",{"name":"trans","hash":{},"data":data}))
    + "</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <p class=\"upper grey\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Draft",{"name":"trans","hash":{},"data":data}))
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <div class=\"meta\">\n            <span class=\"sr-only\">Social data:</span>\n            <span class=\"meta-reads\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + " <span class=\"sr-only\">reads.</span></span>\n            <span class=\"meta-votes\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + " <span class=\"sr-only\">votes.</span></span>\n            <span class=\"meta-comments\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-comment",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.commentCount : depth0),{"name":"count","hash":{},"data":data}))
    + " <span class=\"sr-only\">comments.</span></span>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<!--\n<a class=\"on-story-item on-navigate\" href=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"cover cover-sm shadow\">\n    <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),93,{"name":"resizeCover","hash":{},"data":data}))
    + "\">\n  </div>\n  <small>"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),40,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</small>\n</a>\n-->\n<a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"story-row-cover pull-left on-navigate\">\n    <div class=\"fixed-ratio fixed-ratio-cover\">\n        <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),144,{"name":"resizeCover","hash":{},"data":data}))
    + "\" aria-hidden=\"true\" width=\"94px\" height=\"144px\">\n    </div>\n\n    <h3 class=\"story-title\"><a class=\"on-navigate\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),105,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></h3>\n\n    <!-- if you are the owner of the profile then we show published/draft status -->\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.yourprofile : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <!-- Only show meta data for published stories -->\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.firstPartId : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <p class=\"description\"><span class=\"sr-only\">Description: </span>"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),200,true,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n</a>\n\n";
},"useData":true});
(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * OpenInApp takes elements with class `.on-open-in-app` and attempts to open
   * the deep app link given in appUrl at initialization/setOptions time. If
   * the deep app link fails (because, for example, the app isn't installed
   * yet), the platform's app store is opened to the Wattpad app.
   *
   * Elements with class `.on-download-app` automatically go to the platform's
   * app store.
   *
   * Options (passed to initialize or setOptions):
   *  * appUrl: the deep URL to attempt to load.
   *  * appUrlMedium (optional): The medium to be passed to wattpad.utils.getAppUrl for download tracking.
   *
   * @mixin OpenInApp
   */
  app.add(
    "OpenInApp",
    Monaco.Mixin.create({
      events: {
        "tap .on-open-in-app": "onOpenInApp",
        "click .on-open-in-app": "onOpenInApp",
        "tap .on-download-app": "onDownloadApp",
        "click .on-download-app": "onDownloadApp"
      },

      initialize: function(options, next) {
        this.setOptions(options);

        next(options);
      },

      setOptions: function(options, next) {
        options = options || {};

        this.openAppUrl = options.appUrl ? options.appUrl : "";

        // We don't care by default, but users of the mixin might.
        this.appUrlMedium = undefined;

        next(options);
      },

      onOpenInApp: function(evt, next) {
        var self = this;
        wattpad.utils.stopEvent(evt);

        // chrome 25 doesn't allow iframe method
        if (wattpad.utils.chromeAndroidVer() >= 25) {
          wattpad.utils.redirectToServer(this.openAppUrl);
        } else {
          window.document.getElementById(
            "deeplink-loader"
          ).src = this.openAppUrl; // try deeplink via iframe
          window.setTimeout(function() {
            // failed deeplink, go get app
            window.location.replace(wattpad.utils.getAppUrl(self.appUrlMedium));
          }, 1);
        }

        next(evt);
      },

      onDownloadApp: function(evt, next) {
        wattpad.utils.stopEvent(evt);
        wattpad.utils.redirectToServer(
          wattpad.utils.getAppUrl(this.appUrlMedium)
        );

        next(evt);
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);

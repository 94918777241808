var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<h3 id=\"last-message\"></span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Last Message",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n<a href=\"/user/"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm3 pull-left on-navigate\">\n  "
    + alias3((helpers.connectAvatar || (depth0 && depth0.connectAvatar) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.from : stack1)) != null ? stack1.avatar : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),32,{"name":"connectAvatar","hash":{},"data":data}))
    + "\n</a>\n<a class=\"username on-navigate\" href=\"/user/"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1), depth0))
    + "</a>\n<p class=\"from-now\">"
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.createDate : stack1),{"name":"fromNow","hash":{},"data":data}))
    + "</p>\n\n<pre class=\"body\">"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.body : stack1),200,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n\n<a class=\"btn btn-md btn-block btn-grey btn-left-align on-navigate\" href=\"/user/"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1), depth0))
    + "/conversations\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View all conversations",{"name":"trans","hash":{},"data":data}))
    + "</a> \n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.pinnedItem : depth0)) != null ? stack1.id : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
import privateProfileDisclaimer from "core/templates/profile/private-profile-disclaimer.hbs";
import userLink from "platform/profile/templates/user-link.handlebars";
import userPrivate from "platform/profile/templates/user-private.hbs";
import userDescription from "platform/profile/templates/user-description.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * User Profile Sub View - User Description
   * @constructor
   */
  app.add(
    "ProfileDescription",
    Monaco.View.extend({
      // Properties
      // ----------
      className: "user-description container panel",

      template: {
        public: userDescription,
        private: userPrivate
      },

      partials: {
        link: userLink
      },

      events: {
        "tap   .on-request-addressed": "onRequestAddressed",
        "click .on-request-addressed": "stopEvent",

        "tap .on-show-more": "onShowMore",
        "click .on-show-more": "stopEvent",

        "tap .on-show-less": "onShowLess",
        "click .on-show-less": "stopEvent"
      },

      // Methods
      // -------
      initialize: function(options) {
        this.options = options || {};
        this.isPrivateToMe = options.isPrivateToMe;
        this.requestView = null;
        Handlebars.registerPartial(
          "core.profile.private_profile_disclaimer",
          privateProfileDisclaimer
        );
        Handlebars.registerPartial(
          "core.profile.profile_follow_request_item",
          function() {
            return "";
          }
        );
      },

      render: function() {
        var data = this.model.toJSON(),
          template = this.isPrivateToMe
            ? this.template["private"]
            : this.template["public"],
          followerRequested =
            this.model.get("followerRequest") === "req" ||
            this.model.get("followerRequest") === "ign",
          self = this;

        // data processing for user-description
        if (!this.isPrivateToMe) {
          // remove HTML tags from description for non-HTML-enabled users
          if (data.description && !data.html_enabled) {
            data.description = utils.sanitizeHTML(data.description);
          }
          data.links = this.getLinkValues(data);
        }

        this.$el.html(
          template(
            _.extend(data, {
              followerRequested: followerRequested,
              testGroups: wattpad.testGroups
            })
          )
        );

        if (followerRequested) {
          this.renderRequest();
        }

        if (!this.isPrivateToMe && data.facebook) {
          $.ajax({
            url: "https://graph.facebook.com/" + data.facebook,
            data: { fields: "link" },
            cache: false
          }).done(function(response) {
            self.renderFacebookUrl(response);
          });
        }

        return this;
      },

      renderRequest: function() {
        this.requestView = new app.views.FollowRequestItem({
          model: this.model,
          inProfile: true,
          ignored: this.model.get("followerRequest") === "ign"
        });
        this.$el.find(".request-container").html(this.requestView.render().$el);
      },

      setElement: function(el) {
        var $el = $(el);
        Monaco.View.prototype.setElement.call(this, el);

        if (this.requestView) {
          this.requestView.setElement(
            $el.find("." + this.requestView.className.trim().replace(/ /g, "."))
          );
        }
      },

      // fill in the social data
      getLinkValues: function(data) {
        var links, hasProtocol, name;
        if (!data.facebook && !data.website) {
          return null;
        }

        links = {};
        hasProtocol = /^[\w]{3,5}:\/\//i; // regexp obj for protocol match & replacement
        name = data.name ? data.name.split(" ")[0] : data.username;

        // setup facebook
        if (data.facebook) {
          links.facebook = {};
          links.facebook.name = utils.trans("%s's Facebook profile", name);
          links.facebook.url = "https://www.facebook.com/" + data.facebook;
        }

        // setup website
        if (data.website) {
          links.website = {};
          links.website.name = data.website.replace(hasProtocol, "");
          links.website.url = data.website.match(hasProtocol)
            ? data.website
            : "http://" + data.website;
        }

        // setup smashwords
        if (data.smashwords) {
          links.smashwords = {};
          var linkPath = data.smashwords.split("/");
          links.smashwords.name =
            "smashwords.com/" + linkPath[linkPath.length - 1];
          links.smashwords.url = data.smashwords;
        }

        return links;
      },

      // Get Facebook Vanity URL via graph API
      renderFacebookUrl: function(response) {
        if (response.link) {
          var $el = this.$("li.facebook"),
            data = {
              name: response.link.replace(/^([\w]{3,5}:\/\/)*(w{3}\.)*/i, ""),
              url: response.link
            };

          // render link template with `data` and inject in the DOM
          $el.children("a").remove();
          $el.append(this.partials.link(data));
        }
      },

      onShowMore: function(evt) {
        wattpad.utils.stopEvent(evt);

        this.$(".full-description").removeClass("hidden");
        this.$(".short-description").addClass("hidden");
      },

      onShowLess: function() {
        this.$(".full-description").addClass("hidden");
        this.$(".short-description").removeClass("hidden");
      },

      onRequestAddressed: function(evt) {
        var $btn = $(evt.currentTarget);
        this.requestView.remove();

        if ($btn.hasClass("on-ignore")) {
          this.model.set("followerRequest", "ign");
          this.renderRequest();
        }
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);

import tableOfContents from "platform/story-reading/templates/table-of-contents.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "StoryReadingFunbar",
    app.views.BaseStoryReadingFunbar.extend({
      isDesktop: false,

      initialize: function() {
        Handlebars.registerPartial(
          "mobile.story_reading.table_of_contents",
          tableOfContents
        );
        app.views.BaseStoryReadingFunbar.prototype.initialize.apply(
          this,
          arguments
        );
      },

      render: function() {
        var data = this.model.toJSON();
        data.isDesktop = this.isDesktop;

        this.$el.html(this.template(data));
        return this;
      },

      onNavigate: function(evt) {
        this.$("#funbar-story #table-of-contents").modal("hide");
        app.views.BaseStoryReadingFunbar.prototype.onNavigate.apply(
          this,
          arguments
        );
      },

      //For the LibraryManagement mixin
      onAddToListsReady: function(element, storyId) {
        this.$(".addToLibraryModal[data-story-id='" + storyId + "']").modal(
          "show"
        );
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);

import userWorks from "platform/profile/templates/user-works.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  // TODO: Combine with desktop-web profile-works.js
  app.add(
    "ProfileWorks",
    app.views.IncrementalList.extend({
      id: "profile-works",
      template: userWorks,

      // Item View properties
      itemView: app.views.DiscoverModuleStoriesLarge,
      containerId: "#works-item-view",

      events: _.extend(
        {
          "tap    .on-story-preview": "onStoryPreview",
          "click  .on-story-preview": "stopEvent"
        },
        app.views.IncrementalList.prototype.events
      ),

      initialize: function() {
        this.collection.once("sync", this.render, this);
        app.views.IncrementalList.prototype.initialize.apply(this, arguments);
      },

      getTemplateData: function() {
        var isProfileUser =
          utils.currentUser().get("username") === this.model.get("username");

        return {
          name: this.model.get("name") || this.model.get("username"),
          username: this.model.get("username"),
          totalWorks: this.collection.total,
          numPublished: isProfileUser
            ? this.collection.numPublished
            : this.collection.total,
          numDrafts: this.collection.numDrafts
        };
      },

      getItemViewOptions: function() {
        return {
          isProfileWorksView: true,
          user: {
            fullname: this.model.get("name") || null,
            name: this.model.get("username")
          },
          descCharLimit: 110,
          titleCharLimit: 35
        };
      },

      onStoryPreview: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id");
        var previewModal = new app.views.BrowseStoryPreview({
          showTagRankings: true,
          model: this.collection.get(storyId)
        });

        this.$("#preview-modal")
          .empty()
          .append(previewModal.render().$el);
        previewModal.openModal();

        window.te.push("event", "profile", "works", "story", "click", {
          storyid: storyId,
          username: this.collection.username
        });
      }
    })
  );

  app.mixin(app.views.ProfileWorks, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <li>\n      <a href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/theme\" class=\"on-navigate\">\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Customize Theme",{"name":"trans","hash":{},"data":data}))
    + "\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </a>\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <li>\n        <a href=\"/user/"
    + container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/cookies\" class=\"on-navigate\">\n          Cookie Manager\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </a>\n      </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "<div id=\"user-menu-container\">\n  <ul>\n    <li>\n      <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"on-navigate\">\n        "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"My Profile",{"name":"trans","hash":{},"data":data}))
    + "\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </a>\n    </li>\n";
  stack1 = ((helper = (helper = helpers.isPremiumEligible || (depth0 != null ? depth0.isPremiumEligible : depth0)) != null ? helper : alias2),(options={"name":"isPremiumEligible","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isPremiumEligible) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      <li>\n        <a href=\"/menu/settings\">\n          "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Settings",{"name":"trans","hash":{},"data":data}))
    + "\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </a>\n      </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"COOKIE_MANAGER",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <li>\n      <a href=\"//support.wattpad.com\" target=\"_blank\">\n        "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </a>\n    </li>\n    <li>\n      <a href=\"/logout\" class=\"on-logout\">\n        "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Log Out",{"name":"trans","hash":{},"data":data}))
    + "\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </a>\n    </li>\n  </ul>\n</div>\n";
},"useData":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <main id=\"authentication-panel\" class=\"panel panel-transparent reset-modal\">\n"
    + ((stack1 = container.invokePartial(partials["core.authentication.auth_form"],depth0,{"name":"core.authentication.auth_form","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </main>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <main id=\"authentication-panel\" class=\"panel panel-transparent forgot-modal\">\n"
    + ((stack1 = container.invokePartial(partials["core.authentication.auth_form"],depth0,{"name":"core.authentication.auth_form","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </main>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "             <main id=\"authentication-panel\" class=\"panel panel-transparent login-modal\">\n"
    + ((stack1 = container.invokePartial(partials["core.authentication.auth_form"],depth0,{"name":"core.authentication.auth_form","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </main>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "             <main id=\"authentication-panel \" class=\"panel panel-transparent  signup-modal\">\n"
    + ((stack1 = container.invokePartial(partials["core.authentication.auth_form"],depth0,{"name":"core.authentication.auth_form","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </main>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "            <div class=\"auth-categories-wrapper\">\n                <div class=\"container\">\n                    <div class=\"row\">\n                        <div class=\"col-sm-6\">\n                            <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Take Wattpad With You",{"name":"trans","hash":{},"data":data}))
    + "</p>\n                            <div class=\"row\">\n                                <a href=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.appUrl : depth0)) != null ? stack1.ios : stack1), depth0))
    + "\">\n                                    "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"homepages/appstore_dark.png","Download from the App Store",{"name":"connectImage","hash":{"klass":"footer-appstore"},"data":data}))
    + "\n                                </a>\n                                <a href=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.appUrl : depth0)) != null ? stack1.android : stack1), depth0))
    + "\">\n                                    "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,(depth0 != null ? depth0.googlePlayImage : depth0),"Download from Google Play",{"name":"connectImage","hash":{"klass":"google-play"},"data":data}))
    + "\n                                </a>\n                  \n                            </div>\n                        </div>\n                        <div class=\"col-sm-6\">\n                            "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"homepages/device-mockups-footer.png","Devices",{"name":"connectImage","hash":{"id":"footer-devices"},"data":data}))
    + "\n                        </div>\n                    </div>\n                </div>\n            </div>\n             <div class=\"auth-category-layout footer\">\n"
    + ((stack1 = container.invokePartial(partials["mobile.authentication.auth_footer"],depth0,{"name":"mobile.authentication.auth_footer","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div> \n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"mobile-auth-bg\"></div>\n\n<div class=\"container auth mobile\">\n    <div class=\"row\">\n        <div class=\"col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reset : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.forgot : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.login : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.signup : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.login : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n"
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(alias1,"WPModal","signup-modal",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});
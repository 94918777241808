var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div id=\"blank-search\" class=\"container\">\n        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"BlankSearch","blank-search",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"search-page\">\n\n  <header id=\"search-controls\" class=\"background background-lg\">\n    <div id=\"search-header\">\n      <div class=\"container\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.search_header"],depth0,{"name":"core.search.partials.search_header","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n\n    <div id=\"search-tabs-container\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.search_tabs"],depth0,{"name":"core.search.partials.search_tabs","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </header>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"NEW_SEARCH_LAYOUT",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <main class=\"container"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTerm : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div>\n      <section id=\"section-results-stories\" class=\"results"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.resultType : depth0),"people",{"name":"ifequal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <article id=\"results-stories\">\n          <div class=\"feed-item-new panel new-container\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.results_stories"],depth0,{"name":"core.search.partials.results_stories","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\n        </article>\n      </section>\n\n      <section id=\"results-people\" class=\"results"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.resultType : depth0),"stories",{"name":"ifequal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <div id=\"results-people-region\" class=\"results-people-update\">\n          <div class=\"feed-item-new panel\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.results_people"],depth0,{"name":"core.search.partials.results_people","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </section>\n    </div>\n  </main>\n</div>\n";
},"usePartial":true,"useData":true});
import userAbout from "platform/profile/templates/user-about.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * User Profile Sub View - User Description
   * @constructor
   */
  app.add(
    "ProfileAbout",
    Monaco.View.extend({
      className: "user-about",

      template: userAbout,

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};
        this.isPrivateToMe = options.isPrivateToMe;
        this.requestView = null;

        this.descriptionView = new app.views.ProfileDescription({
          model: this.model,
          isPrivateToMe: this.isPrivateToMe
        });

        this.worksView = new app.views.ProfileWorks({
          collection: new app.collections.ProfileWorks([], {
            username: this.model.get("username"),
            limit: 3
          }),
          model: this.model,
          manualRegistration: true // Don't infinite scroll
        });

        this.followingSectionView = new app.views.ProfileFollowingSection({
          model: this.model,
          collection: new app.collections.ProfileFollowing([], {
            username: this.model.get("username"),
            limit: 4
          }),
          section: options.section,
          setTitle: options.setTitle
        });

        this.pinnedMessageView = new app.views.PinnedMessage({
          model: new app.models.PinnedMessage({
            username: this.model.get("username")
          })
        });

        this.listsView = new app.views.ProfileLists({
          collection: new app.collections.ReadingLists([], {
            username: this.model.get("username"),
            limit: 2,
            nonEmpty: true
          }),
          username: this.model.get("username"),
          manualRegistration: true // Don't infinite scroll
        });

        this.listenTo(this, "scroll-to-element", this.scrollToElement);
        this.listenTo(
          this.listsView,
          "scroll-to-element",
          this.scrollToElement
        );
      },

      render: function() {
        var data = _.extend(
          {
            renderAll: !this.isPrivateToMe,
            socialShareSection: this.getSocialShareHTML()
          },
          this.model.toJSON()
        );

        this.$el.html(this.template(data));

        this.descriptionView.setElement(this.$(".user-description"));
        this.descriptionView.render();

        if (data.renderAll) {
          this.worksView.setElement(this.$("#profile-works"));
          this.followingSectionView.setElement(
            this.$("#user-following-section")
          );
          this.listsView.setElement(this.$("#profile-lists"));

          // Fetch collection data
          this.worksView.collection.fetchNextSet();
          this.followingSectionView.collection.fetchNextSet();

          var self = this;
          Promise.resolve(this.pinnedMessageView.model.fetch()).then(
            function() {
              if (self.pinnedMessageView.model.get("id")) {
                self.pinnedMessageView.setElement(
                  self.$("#profile-pinned-item")
                );
                self.pinnedMessageView.render();
              }
            }
          );
        }

        return this;
      },

      setElement: function($el) {
        Monaco.View.prototype.setElement.apply(this, arguments);

        if (this.descriptionView) {
          this.descriptionView.setElement(this.$(".user-description"));
          this.worksView.setElement(this.$("#profile-works"));
          this.followingSectionView.setElement(
            this.$("#user-following-section")
          );
          this.listsView.setElement(this.$("#profile-lists"));
        }
      },

      setScrollToElementId: function(elementId, fireTriggerNow) {
        this.scrollToElementId = elementId;
        if (fireTriggerNow) {
          this.trigger("scroll-to-element");
        }
      },

      scrollToElement: function() {
        if (this.scrollToElementId) {
          $("html, body").animate(
            {
              scrollTop: this.$("#" + this.scrollToElementId).offset().top
            },
            500
          );
        }
      },

      getSocialShareHTML: function() {
        var meta = {
          username: this.model.get("username"),
          name: this.model.get("name") || this.model.get("username"),
          avatarUrl: this.model.get("avatar"),
          description: this.model.get("description")
        };

        var links = this.buildSocialShareLinksForProfile(meta);

        return this.getSocialShareTemplateHTML("row", links);
      }
    })
  );

  app.mixin(app.views.ProfileAbout, "SocialSharing");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);

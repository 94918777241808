var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<form id=\"search-form\" method=\"GET\" action=\"/search/\" class=\"short\">\n  <div class=\"form-group has-feedback\">\n    <label for=\"search-query\" class=\"control-label sr-only\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search by title or topic",{"name":"trans","hash":{},"data":data}))
    + "></label>\n    <input id=\"search-query\" name=\"query\" type=\"search\" autocomplete=\"off\" spellcheck=\"false\" autocorrect=\"off\" autocapitalize=\"off\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search by title or topic",{"name":"trans","hash":{},"data":data}))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.query || (depth0 != null ? depth0.query : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"query","hash":{},"data":data}) : helper)))
    + alias3(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\">\n    <span class=\"form-control-feedback\">\n      <button type=\"submit\" class=\"btn-search on-search\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-search",18,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n    </span>\n  </div>\n\n  <div class=\"dropdown-menu items\" aria-label=\"search-dropdown\">\n    <ul aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Autocomplete",{"name":"trans","hash":{},"data":data}))
    + "\" class=\"autocomplete-item-container\"></ul>\n  </div>\n</form>\n";
},"useData":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div id=\"generic-modal\" class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <header class=\"modal-header\">\n            <div class=\"close\" data-dismiss=\"modal\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",28,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </header>\n        <div class=\"modal-body clearfix\">\n            <div id=\"subscribe-cta\" class=\"background\">\n                <p class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Discover the best reading experience with Wattpad Premium",{"name":"trans","hash":{},"data":data}))
    + "</p>\n                <p class=\"description\">\n                    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Upgrade to discover a new layer of Wattpad with premium themes, an ad-free experience, and to show support for the community you love.",{"name":"trans","hash":{},"data":data}))
    + "\n                </p>\n            </div>\n            <div>\n                "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/premium-modal/step-3@2x.png","You're premium!",{"name":"connectImage","hash":{"klass":"app-img"},"data":data}))
    + "\n            </div>\n            <a href=\""
    + alias3(((helper = (helper = helpers.appUrl || (depth0 != null ? depth0.appUrl : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"appUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-orange on-track-event subscribe-now\" data-action-type=\"goto-app\" aria-label=\"Subscribe Now\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Subscribe Now",{"name":"trans","hash":{},"data":data}))
    + "</a>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
import userActivityFeed from "platform/profile/templates/user-conversations.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * User Profile Sub View - Activity Feed
   * @Class ActivityFeed
   * @constructor
   */

  app.add(
    "ProfileConversations",
    app.views.CoreProfileActivity.extend({
      // Properties
      // ----------
      className:
        app.views.CoreProfileActivity.prototype.className + " container",
      template: userActivityFeed,

      initialize: function(options) {
        options = options || {};
        var currentUser = wattpad.utils.currentUser();
        if (
          currentUser.authenticated() &&
          currentUser.get("username") === this.model.get("username")
        ) {
          options.placeholder = wattpad.utils.trans("Post a Message");
        } else {
          options.placeholder = wattpad.utils.trans(
            "Write to %s",
            this.model.get("username")
          );
        }

        app.views.CoreProfileActivity.prototype.initialize.apply(
          this,
          arguments
        );
      },

      getTemplateData: function() {
        var data = this.model.toJSON();
        data.numMessages = this.collection.length > 0;
        return data;
      },

      addNewMessage: function(message) {
        var self = this;
        this.setDirection("up");
        if (this.collection instanceof app.collections.ActivityMessages) {
          message.set("type", message.type || "newMessage");
          message.set("username", this.model.get("username"));
          this.collection.add(message.toJSON());
        } else {
          this.collection.add(
            new app.models.PublicMessage({
              id: message.get("eventId"),
              type: "message",
              createDate: message.get("createDate"),
              data: {
                user: {
                  name: message.get("from").name,
                  avatar: message.get("from").avatar
                },
                message: {
                  id: message.get("id"),
                  createDate: message.get("createDate"),
                  body: message.get("body"),
                  to: {
                    name: this.model.get("username"),
                    avatar: this.model.get("avatar")
                  },
                  broadcast: message.get("isBroadcast"),
                  isOffensive: message.get("isOffensive")
                },
                highlight_color: "#00B2B2"
              },
              username: this.model.get("username")
            })
          );
        }
        this.setDirection("down");
        app.local.clear(this.collection.resource());
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"interstitial-wrapper\">\n    <div class=\"row\">\n        <a id=\"skip\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",30,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n        <div id=\"copy\">\n            <div id=\"logo\" alt=\"Wattpad\"></div>\n            <h2>\n                "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Read and create stories, even when you're offline.",{"name":"trans","hash":{},"data":data}))
    + "\n            </h2>\n            <a class=\"btn btn-orange CTA\" href=\"https://bnc.lt/download-takeover-exp\" data-gtm=\"Download|appinterrupt_mw|"
    + alias3(((helper = (helper = helpers.platform || (depth0 != null ? depth0.platform : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"platform","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Continue in App",{"name":"trans","hash":{},"data":data}))
    + "</a>\n            <div id=\"rating-container\">\n                <div id=\"stars\"></div>\n                <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"1,000,000+ 5 star ratings",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            </div>\n        </div>\n        <div id='devices-container'>\n          <div id='devices'></div>\n        </div>\n        <iframe style=\"display:none\" height=\"0\" width=\"0\" id=\"deeplink-loader\"></iframe>\n    </div>\n</div>\n";
},"useData":true});
import userFollowingSection from "platform/profile/templates/user-following-section.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  /**
   * User Profile Sub View - User Following Section
   * @constructor
   */
  app.add(
    "ProfileFollowingSection",
    app.views.Base.extend({
      id: "user-following-section",
      template: userFollowingSection,

      events: {
        "tap   .on-following": "showFollowingModal",
        "click .on-following": "stopEvent"
      },

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};
        this.section = options.section;
        this.setTitle = options.setTitle;

        this.collection.once("sync", this.render, this);
      },

      render: function() {
        var templateData = { users: this.collection.toJSON().splice(0, 4) };

        // For the following +num button thing
        var totalFollowings = this.collection.total;

        templateData.numFollowingBreak1 =
          totalFollowings > 2 ? totalFollowings - 2 : null;
        templateData.numFollowingBreak2 =
          totalFollowings > 3 ? totalFollowings - 3 : null;
        templateData.numFollowingBreak3 =
          totalFollowings > 4 ? totalFollowings - 4 : null;

        this.$el.html(this.template(templateData));

        // Open the following modal if navigating to "/following" url
        if (this.section === "following") {
          this.isFollowingSection = true;
          this.showFollowingModal();
        }

        return this;
      },

      showFollowingModal: function(evt) {
        var self = this;
        var collection = new app.collections.ProfileFollowing([], {
          username: this.model.get("username"),
          limit: 10
        });

        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthPrompt(evt);
        } else {
          Promise.resolve(collection.fetchNextSet()).then(function() {
            var view = new app.views.ProfileFollowersFollowingModal({
              model: self.model,
              collection: collection,
              showFollowing: true
            });

            self.setTitle("following");
            self
              .$("#following-modal")
              .empty()
              .append(view.render().$el);
            view.showModal();

            // Listening to the following modal close event
            self.$("#following-modal").on("hidden.bs.modal", function() {
              // We don't want "/following" url to be it's own state since it's a modal
              if (self.isFollowingSection) {
                self.isFollowingSection = false;
                app.router.navigate("/user/" + self.model.get("username"), {
                  replace: true
                });
              }
              // Reset page title
              self.setTitle();
              view.remove();
            });
          });
        }
      }
    })
  );

  app.mixin(app.views.ProfileFollowingSection, "AuthPromptManagement");
})(window, _, jQuery, wattpad, wattpad.utils, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <a class=\"btn btn-more btn-grey btn-block on-update-feed\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"See latest activity.",{"name":"trans","hash":{},"data":data}))
    + "\n  </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div id=\"profile-activity\" class=\"collection\">"
    + ((stack1 = ((helper = (helper = helpers.itemsContent || (depth0 != null ? depth0.itemsContent : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"itemsContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n  <div class=\"show-more"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMore : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + " center-text\">\n    <button class=\"btn btn-link on-showmore\">\n        <span class='show-more-message'>\n            <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n        </span>\n        <span class='loading-message hidden'>\n            "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n            <span> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading",{"name":"trans","hash":{},"data":data}))
    + " ...</span>\n        </span>\n    </button>\n  </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"6":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"collection\"><p class=\"no-item\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"No more updates for now.",{"name":"trans","hash":{},"data":data}))
    + "</p></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = "";

  stack1 = ((helper = (helper = helpers.showActivityOutofDate || (depth0 != null ? depth0.showActivityOutofDate : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"showActivityOutofDate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.showActivityOutofDate) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numMessages : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
import mobileBanner from "platform/shared/templates/mobile-banner.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Banner",
    Monaco.View.extend({
      className: "banner row",

      template: mobileBanner,

      events: {
        "tap .on-signup": "onSignupSelected",
        "click .on-signup": "stopEvent",
        "tap .on-install": "onInstallSelected",
        "click .on-install": "stopEvent"
      },

      render: function() {
        this.$el.html(
          this.template({
            downloadAppUrl: wattpad.utils.getAppUrl()
          })
        );
        return this;
      },

      onSignupSelected: function(evt) {
        wattpad.utils.stopEvent(evt);

        app.router.navigate("/?nexturl=" + window.location.href, {
          trigger: true
        });
      },

      onInstallSelected: function(evt) {
        wattpad.utils.stopEvent(evt);
        this.remove();
        wattpad.utils.redirectToServer(wattpad.utils.getAppUrl());
      },

      stopEvent: function(evt) {
        wattpad.utils.stopEvent(evt);
      }
    })
  );
})(window, _, wattpad, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<span class=\"deleted-tag\">("
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted",{"name":"trans","hash":{},"data":data}))
    + ")</span>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<span class=\"draft-tag\">("
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Draft",{"name":"trans","hash":{},"data":data}))
    + ")</span>";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, buffer = 
  "          <li class=\"";
  stack1 = ((helper = (helper = helpers.active || (depth0 != null ? depth0.active : depth0)) != null ? helper : alias2),(options={"name":"active","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.active) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n            <a class=\"on-navigate"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBlocked : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{"locals":depths[1]},"data":data}))
    + "\">\n              <div class=\"part-title\">\n                "
    + alias3(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.deleted : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.draft : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n              </div>\n              "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBlocked : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </a>\n          </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "active";
},"8":function(container,depth0,helpers,partials,data) {
    return " blocked";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-lock",16,"wp-neutral-2","pull-right",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<div id=\"table-of-contents\" class=\"tableOfContents modal fade\" aria-labelledby=\"table-of-contents-modal-label\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <header class=\"modal-header\">\n        <div class=\"pull-right close top-right\" data-dismiss=\"modal\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove-alt",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </header>\n      <div class=\"modal-body\">\n        <div class=\"story-group\">\n          <div class=\"cover cover-sm\">\n            <a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-navigate\"><img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.cover : stack1),27,{"name":"resizeCover","hash":{},"data":data}))
    + "\"></a>\n          </div>\n          <div class=\"info\">\n            <h2>\n              <a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-navigate\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.title : stack1), depth0))
    + "</a>\n              "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.deleted : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.draft : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </h2>\n            <h4 class=\"author\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by",{"name":"trans","hash":{},"data":data}))
    + " <a href=\"/user/"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"on-navigate\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1), depth0))
    + "</a></h4>\n          </div>\n        </div>\n        <ul class=\"table-of-contents list-unstyled\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.parts : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n\n    </div>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});
import storyItemSmall from "platform/shared/templates/item/story-item-small.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "StoryItemSmall",
    app.views.StoryItem.extend({
      template: storyItemSmall,
      className: "story-row col-xs-12",

      render: function() {
        var data = this.model.toJSON();
        data.url = utils.formatStoryUrl(this.model.get("url"));

        this.$el.empty().append(this.template(data));
        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);

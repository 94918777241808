var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MenuModal","topics",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MenuOptions","topics",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
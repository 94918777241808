(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "BrowseCategory",
    app.views.CoreBrowseCategory.extend({
      onChangeCategory: function(evt) {
        var self = this;
        var categorySlug = $(evt.currentTarget).data("category");
        var category = app
          .get("categories")
          .find(function(model, index, collection) {
            return (
              wattpad.utils.slugify(model.get("name_english")) ===
              categorySlug.toLowerCase()
            );
          });

        app.router.navigate("/browse/" + categorySlug);
        this.category = category;
        this.filter = "hot"; //reset the filters

        this.$("#categories-modal").one("hidden.bs.modal", function() {
          self.render();
        });
        this.$("#categories-modal").modal("hide");
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);

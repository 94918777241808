import userLists from "platform/profile/templates/user-lists.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "ProfileLists",
    app.views.IncrementalList.extend({
      className: "reading-lists",
      template: userLists,

      itemView: app.views.ReadingListItem,
      containerId: "#lists-item-view",

      events: _.extend(
        {
          "tap .on-story-preview": "onNavigate",
          "click .on-story-preview": "stopEvent"
        },
        app.views.IncrementalList.prototype.events
      ),

      initialize: function(options) {
        options = options || {};
        this.username = options.username;

        var self = this;
        Promise.resolve(this.collection.fetchNextSet())
          .then(function() {
            self.render();
          })
          .then(function() {
            // Delay because jQuery `ready` doesn't ensure that everything
            // on the page has been rendered.
            _.delay(function() {
              self.trigger("scroll-to-element");
            }, 500);
          });
        app.views.IncrementalList.prototype.initialize.apply(this, arguments);
      },

      getTemplateData: function() {
        return {
          totalLists: this.collection.total,
          username: this.username
        };
      }
    })
  );

  app.mixin(app.views.ProfileLists, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app);

(function(window, app) {
  "use strict";

  app.router.add({
    "search(/:terms)(/:resultType)": [
      "node-search",
      {
        regexp: {
          terms: /[^\?\/]+/,
          resultType: /stories|people/
        }
      }
    ]
  });
})(window, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"active\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <li data-section=\"featured\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.active : depth0)) != null ? stack1.featured : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <a href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name_english : stack1),{"name":"slugify","hash":{},"data":data}))
    + "/featured\" class=\"on-tab-nav\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Featured",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                    </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " class=\"active\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<header class=\"background background-lg text-left category-banner\">\n    <div class=\"container\">\n        <h2 class=\"subtitle\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Category",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n        <button class=\"btn btn-text on-change-category\" data-category-id=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">\n            <h1 class=\"h2\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name : stack1), depth0))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-down-small",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</h1>\n        </button>\n        <h2 class=\"subtitle\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.subtitle : stack1), depth0))
    + "</h2>\n    </div>\n</header>\n\n<div id=\"filter-tabs\" class=\"sub-navigation\">\n    <div class=\"container\">\n        <nav class=\"pull-left\">\n            <ul>\n                <li data-section=\"hot\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.active : depth0)) != null ? stack1.hot : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    <a href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name_english : stack1),{"name":"slugify","hash":{},"data":data}))
    + "\" class=\"on-tab-nav\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Hot",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEnglish : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                <li data-section=\"new\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.active : depth0)) != null ? stack1["new"] : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    <a href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name_english : stack1),{"name":"slugify","hash":{},"data":data}))
    + "/new\" class=\"on-tab-nav\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                </li>\n            </ul>\n        </nav>\n        <div class=\"actions pull-right\" role=\"menu\">\n            <div class=\"button-group relative inline-block\">\n                <button class=\"btn btn-text dropdown-toggle\" data-toggle=\"dropdown\">\n                  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                </button>\n                <div class=\"triangle\"></div>\n                <ul class=\"dropdown-menu dropdown-menu-right\">\n                    <li><a href=\"#\" role=\"button\" class=\"on-filter-completed\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Only show completed",{"name":"trans","hash":{},"data":data}))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",14,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n                </ul>\n            </div>\n        </div>\n    </div>\n</div>\n\n<main id=\"tab-content\" class=\"container\">\n    <div class=\"stories\">\n"
    + ((stack1 = container.invokePartial(partials["core.collection_views.browse_stories"],depth0,{"name":"core.collection_views.browse_stories","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</main>\n\n"
    + ((stack1 = container.invokePartial(partials["core.components.choose_category_modal"],depth0,{"name":"core.components.choose_category_modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <div id=\"browse-label\">"
    + container.escapeExpression(((helper = (helper = helpers.browseLabel || (depth0 != null ? depth0.browseLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"browseLabel","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"TagCarousel","relatedTags",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.tabs : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.tabValue : depth0),"featured",{"name":"ifequal","hash":{},"fn":container.noop,"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "          <li>\n            <a href=\"/"
    + alias1(container.lambda((depths[1] != null ? depths[1].urlBasePath : depths[1]), depth0))
    + "/"
    + alias1(((helper = (helper = helpers.tabValue || (depth0 != null ? depth0.tabValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"tabValue","hash":{},"data":data}) : helper)))
    + "\" data-section=\""
    + alias1(((helper = (helper = helpers.tabValue || (depth0 != null ? depth0.tabValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"tabValue","hash":{},"data":data}) : helper)))
    + "\"\n               class=\"tab-control"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias3).call(alias2,(depths[1] != null ? depths[1].activeTab : depths[1]),(depth0 != null ? depth0.tabValue : depth0),{"name":"ifequal","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n              "
    + alias1(((helper = (helper = helpers.translatedTabName || (depth0 != null ? depth0.translatedTabName : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"translatedTabName","hash":{},"data":data}) : helper)))
    + "\n            </a>\n          </li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " active";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.tabs : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "        <li>\n          <a href=\"/"
    + alias1(container.lambda((depths[1] != null ? depths[1].urlBasePath : depths[1]), depth0))
    + "/"
    + alias1(((helper = (helper = helpers.tabValue || (depth0 != null ? depth0.tabValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"tabValue","hash":{},"data":data}) : helper)))
    + "\" data-section=\""
    + alias1(((helper = (helper = helpers.tabValue || (depth0 != null ? depth0.tabValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"tabValue","hash":{},"data":data}) : helper)))
    + "\"\n             class=\"tab-control"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias3).call(alias2,(depths[1] != null ? depths[1].activeTab : depths[1]),(depth0 != null ? depth0.tabValue : depth0),{"name":"ifequal","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            "
    + alias1(((helper = (helper = helpers.translatedTabName || (depth0 != null ? depth0.translatedTabName : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"translatedTabName","hash":{},"data":data}) : helper)))
    + "\n          </a>\n        </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MenuOptions","exploreTags",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"browse-results\">\n"
    + ((stack1 = container.invokePartial(partials["mobile.browse.browse_results"],depth0,{"name":"mobile.browse.browse_results","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = "";

  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isLoggedIn","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.isLoggedIn) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.relatedTags : depth0)) != null ? stack1.tags : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<nav>\n  <ul>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEnglish : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "  </ul>\n</nav>\n\n<main id=\"browse-content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showExploreList : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</main>\n";
},"usePartial":true,"useData":true,"useDepths":true});
var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "\n  <div id=\"follower-modal\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div id=\"settings-modal\" class=\"modal fade\" role=\"dialog\"></div>\n  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"WPModal",(depth0 != null ? depth0.username : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "  <div id=\"profile-card\" class=\"row background\"\n         style=\"background-image: url('"
    + container.escapeExpression((helpers.profileBackground || (depth0 && depth0.profileBackground) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.backgroundUrl : depth0),320,{"name":"profileBackground","hash":{},"data":data}))
    + "');\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div id=\"profile-card\" class=\"row background\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.highlight_colour : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " >\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\n         style=\"background-color: "
    + container.escapeExpression(((helper = (helper = helpers.highlight_colour || (depth0 != null ? depth0.highlight_colour : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"highlight_colour","hash":{},"data":data}) : helper)))
    + "\" ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <div class=\"profile-header-buttons\">\n            <span>\n              "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"WalletWithOnboarding","profile",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </span>\n\n            <button class=\"btn-no-background settings on-settings\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-settings",24,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </button>\n        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                "
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                "
    + container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data}) : helper)))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "          <div class=\"full-name\">@"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "</div>\n\n          <div class=\"row header-metadata\">\n            <div class=\"col-xs-4 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\"profile-works\">\n              <p>"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"count","hash":{},"data":data}))
    + "</p>\n              <p>"
    + alias3((helpers.npgettext || (depth0 && depth0.npgettext) || alias2).call(alias1,"Profile Metadata","Work","Works",(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"npgettext","hash":{},"data":data}))
    + "</p>\n            </div>\n            <div class=\"col-xs-4 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numLists : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\"profile-lists\">\n              <p>"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numLists : depth0),{"name":"count","hash":{},"data":data}))
    + "</p>\n              <p>"
    + alias3((helpers.npgettext || (depth0 && depth0.npgettext) || alias2).call(alias1,"Profile Metadata","Reading List","Reading Lists",(depth0 != null ? depth0.numLists : depth0),{"name":"npgettext","hash":{},"data":data}))
    + "</p>\n            </div>\n            <div class=\"col-xs-4 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n              <p class=\"followers-count\">"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"count","hash":{},"data":data}))
    + "</p>\n              <p>"
    + alias3((helpers.npgettext || (depth0 && depth0.npgettext) || alias2).call(alias1,"Profile Metadata","Follower","Followers",(depth0 != null ? depth0.numFollowers : depth0),{"name":"npgettext","hash":{},"data":data}))
    + "</p>\n            </div>\n          </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "scroll-to-element";
},"20":function(container,depth0,helpers,partials,data) {
    return "on-followers";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isPrivate : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(28, data, 0),"data":data})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.followingRequested : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "          <button class=\"btn btn-left-align btn-teal on-following-request\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-following-request",18,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Requested",{"name":"trans","hash":{},"data":data}))
    + "\n          </button>\n\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "          <button class=\"btn btn-left-align btn-white on-following-request\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",18,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Request",{"name":"trans","hash":{},"data":data}))
    + "\n          </button>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "          <button class=\"btn btn-fan "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.following : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = helpers.disabled || (depth0 != null ? depth0.disabled : depth0)) != null ? helper : alias2),(options={"name":"disabled","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.disabled) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " on-follow-user\" data-target=\""
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" data-following=\""
    + alias4(((helper = (helper = helpers.following || (depth0 != null ? depth0.following : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"following","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.following : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "          </button>\n          <div class=\"button-group inline-block relative\">\n           \n            <button class=\"btn btn-white dropdown-toggle\" data-toggle=\"dropdown\" role=\"button\"\n                aria-controls=\"profile-more-options\" aria-expanded=\"false\" aria-label=\"more options\">\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </button>       \n            <div class=\"triangle\" ></div>\n            <div id=\"profile-more-options\" class=\"dropdown-menu align-right\">\n              <div class=\"user-safety-operations\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPrivateToMe : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(39, data, 0),"data":data})) != null ? stack1 : "")
    + "                    <button class=\"on-report user-safety-operation btn-no-background btn-left-icon\"  data-username= "
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\n                      data-toggle=\"modal\" data-action=\"reportUser\">\n                      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"report",14,"ds-neutral-100",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Report %s",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data}))
    + "</span>\n                    </button>\n\n                    <a class=\"conduct-link user-safety-operation btn-no-background btn-left-icon\" href=\"https://support.wattpad.com/hc/en-us/articles/200774234-Code-of-Conduct\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"link",14,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Code of Conduct",{"name":"trans","hash":{},"data":data}))
    + "</span> </a>\n\n                    <a class=\"safety-link user-safety-operation btn-no-background btn-left-icon\" href=\"https://safetyportal.wattpad.com/\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"link",14,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Safety Portal",{"name":"trans","hash":{},"data":data}))
    + "</span> </a>\n\n                    <a class=\"policy-link user-safety-operation btn-no-background btn-left-icon\" href=\"https://policies.wattpad.com/\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"link",14,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Policies",{"name":"trans","hash":{},"data":data}))
    + "</span> </a>\n              </div>\n            </div>        \n         </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "btn-teal";
},"31":function(container,depth0,helpers,partials,data) {
    return "btn-white";
},"33":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-following",18,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<span class=\"truncate\"> "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Following",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",18,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<span class=\"truncate\"> "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Follow",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isLoggedIn","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isLoggedIn) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showMuteOption : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMuted : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBlocked : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(46, data, 0),"data":data})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    <button role=\"button\" href=\"#\" class=\"on-unmute user-safety-operation btn-no-background btn-left-icon\"\n                      data-toggle=\"modal\" data-action=\"unmuteUser\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"unmute",16,"ds-neutral-100",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unmute %s",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data}))
    + "</span></button>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    <button class=\"on-mute user-safety-operation btn-no-background btn-left-icon mute\"  data-toggle=\"modal\" data-action=\"muteUser\">\n                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"mute",14,"ds-neutral-100",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Mute %s",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data}))
    + "</span>\n                    </button>\n                   \n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                  <button class=\"on-block user-safety-operation btn-no-background btn-left-icon block\"  data-toggle=\"modal\" data-action=\"blockUser\">\n                  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"block",14,"ds-neutral-100","","stroke-width=\"0\" viewBox=\"0 0 16 16\"",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Block %s",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data}))
    + "</span>\n                  </button>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <ul id=\"profile-nav\" class=\"row\">\n    <li class=\"nav-item about\">\n      <a class=\"on-nav-item"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active_about : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/about\"\n        data-section=\"about\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"About",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    </li>\n    <li class=\"nav-item conversations\">\n      <a class=\"on-nav-item"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active_conversations : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/conversations\"\n        data-section=\"conversations\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Conversations",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    </li>\n\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </ul>\n";
},"49":function(container,depth0,helpers,partials,data) {
    return " selected";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || helpers.helperMissing).call(alias1,"PROFILE_QUESTS",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <li class=\"nav-item quests\">\n                <a class=\"on-nav-item"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active_quests : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/quests\" data-section=\"quests\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Quests",{"name":"trans","hash":{},"data":data}))
    + "</a>\n            </li>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Toast","unmute-info",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Toast","unblock-info",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"BuyCoinsReactModal","buy-coins-modal",(depth0 != null ? depth0.buyCoinsModal : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isPrivateToMe : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.backgroundUrl : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"container center-text\">\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n      <a class=\"avatar avatar-profile on-nav-item\" href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" data-section=\"about\">\n        <img src=\""
    + alias4((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),96,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n      </a>\n\n      <div class=\"badges\">\n        <h2 class=\"user-name\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "        </h2>\n"
    + ((stack1 = container.invokePartial(partials["core.components.user_badges"],depth0,{"name":"core.components.user_badges","hash":{"badgeSize":"20"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isPrivateToMe : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.noop,"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isPrivateToMe : depth0),{"name":"unless","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <div id=\"profile-content\" class=\"row\">"
    + ((stack1 = ((helper = (helper = helpers.section_content || (depth0 != null ? depth0.section_content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"section_content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n  <div id=\"profile-modal-container\"></div>\n</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.recentlyUnmuted : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.recentlyUnblocked : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});
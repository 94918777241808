/*
 * ModalTransition
 * - hack job, to be removed when onboarding is changed to NOT use modals
 */
(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "ModalTransition",
    app.transitions.DefaultTransition.extend({
      start: function(fromView, toView, options) {
        options = options || {};

        // SSR load
        if (!fromView) {
          this._canonicalURL(options); // clean up URL
          //when people hit the forgot password page, we dont want to tell them to download the
          //app - they may have just come from there
          if (toView.options.page !== "forgot") {
            this.showInterstitial();
          }
        } else {
          // CSR load
          this._pageView();
          // Set the page title ( don't need to set it on SSR )
          if (options.pageTitle && options.pageTitle.length > 0) {
            wattpad.utils.setTitle(options.pageTitle);
          }
        }

        this.changeViews(fromView, toView, options);

        //manipulate header
        if (options.hasHeader) {
          this.showHeader(!fromView);
        } else {
          this.hideHeader();
        }
        //manipulate footer
        if (options.hasFooter) {
          this.showFooter(!fromView, options.footerOptions);
        } else {
          this.hideFooter();
        }

        wattpad.utils.hideAddressBar();

        this.scrollTo(window, fromView);

        return toView;
      }
    })
  );
})(window, Monaco, wattpad, window.app);

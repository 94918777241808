var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<main id=\"getapp-mobile\">\n  <div id=\"cta\" class=\"text-center\">\n    <h1>\n      "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Take stories with you.",{"name":"trans","hash":{},"data":data}))
    + "\n    </h1>\n\n    <a href=\""
    + alias3(((helper = (helper = helpers.downloadAppUrl || (depth0 != null ? depth0.downloadAppUrl : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"downloadAppUrl","hash":{},"data":data}) : helper)))
    + "\" data-name=\"Download|getmobile|"
    + alias3(((helper = (helper = helpers.mobileOS || (depth0 != null ? depth0.mobileOS : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mobileOS","hash":{},"data":data}) : helper)))
    + "\"data-gtm=\"Download|getmobile|"
    + alias3(((helper = (helper = helpers.mobileOS || (depth0 != null ? depth0.mobileOS : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mobileOS","hash":{},"data":data}) : helper)))
    + "\">\n      "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,(depth0 != null ? depth0.appStoreImage : depth0),(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Download App",{"name":"trans","hash":{},"data":data}),{"name":"connectImage","hash":{"klass":"appstore-image"},"data":data}))
    + "\n    </a>\n    "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"homepages/5stars.png","Stars",{"name":"connectImage","hash":{"klass":"ratings-stars"},"data":data}))
    + "\n    <small>"
    + alias3(((helper = (helper = helpers.ratingsText || (depth0 != null ? depth0.ratingsText : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ratingsText","hash":{},"data":data}) : helper)))
    + "</small>\n  </div>\n  <div id=\"device\" class=\""
    + alias3(((helper = (helper = helpers.languageCode || (depth0 != null ? depth0.languageCode : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"languageCode","hash":{},"data":data}) : helper)))
    + " "
    + alias3(((helper = (helper = helpers.mobileOS || (depth0 != null ? depth0.mobileOS : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mobileOS","hash":{},"data":data}) : helper)))
    + "\"></div>\n</main>\n"
    + ((stack1 = container.invokePartial(partials["core.getmobile.values"],depth0,{"name":"core.getmobile.values","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
import mapSignupErrorToMsg from "../../../../../helpers/signup-map-error-to-msg";

(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:authentication",
    app.router.filter(["getTranslations"], function(page) {
      page = page || "signup";

      let authToken = new URLSearchParams(window.location.search).get("at");

      if (wattpad.utils.isHttp()) {
        wattpad.utils.redirectToServer(window.location.href);
      } else {
        var authenticationView = new app.views.Authentication({
          model: new app.models["Auth" + page]({}),
          page: page,
          authToken: authToken
        });

        app.transitionTo(authenticationView, {
          hideAddressBar: false,
          hasHeader: true,
          hasFooter: true,
          hideAnnouncements: true
        });
      }
    })
  );

  app.router.on(
    "route:oauth-auth",
    app.router.filter(["getTranslations"], function(page) {
      const data = JSON.parse(wattpad.utils.getParam("data"));
      let authView;
      if (data?.formType === "facebook") {
        authView = generateFacebookAuthAdditionalDetailsView(data);
      } else {
        authView = generateGoogleAuthAdditionalDetailsView(data);
      }
      app.transitionTo(authView, {
        hasHeader: false,
        hideOneTap: true,
        hideAddressBar: false,
        hideAnnouncements: true,
        isCaseSensitiveUrl: true
      });
    })
  );

  const authviewWrapper = {
    model: new app.models.Authsignup({})
  };

  const generateFacebookAuthAdditionalDetailsView = data => {
    const preservedVal = {
      email: data.email,
      isEmailAvailable: data.isEmailAvailable,
      isBirthdayAvailable: data.isBirthdayAvailable
    };
    const authView = new app.views.FacebookAuthentication({
      ...authviewWrapper,
      preservedVal,
      error: data.error
    });
    return authView;
  };

  const generateGoogleAuthAdditionalDetailsView = data => {
    const preservedVal = {
      email: data.email,
      token: data.token,
      type: data.type,
      username: data.username
    };
    const authView = new app.views.OauthAuthentication({
      ...authviewWrapper,
      preservedVal,
      errorMsg:
        data.error && mapSignupErrorToMsg(data.error, wattpad.utils.trans)
    });
    return authView;
  };
})(window, _, jQuery, wattpad, window.app);

import storiesSimilar from "core/templates/collection-views/stories-similar.hbs";
import addToLibraryModal from "core/templates/components/add-to-library-modal.hbs";
import downloadAppCta from "core/templates/story-reading/cta.hbs";
import mobileStoryReading from "platform/story-reading/templates/mobile-story-reading.hbs";

(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "StoryReading",
    app.views.CoreStoryReading.extend({
      template: mobileStoryReading,

      isDesktop: false,

      partials: _.extend(app.views.CoreStoryReading.prototype.partials, {
        "core.components.add_to_library_dropdown": function() {
          return "";
        },
        "core.components.share_tools": function() {
          return "";
        },
        "core.story_reading.cta": downloadAppCta,
        "core.components.add_to_library_modal": addToLibraryModal
      }),

      className: "container",

      regions: {},

      subviews: {
        funbar: app.views.StoryReadingFunbar,
        media: app.views.MediaBanner,
        comments: app.views.Comments
      },

      events: _.extend(
        {
          "tap    .panel-reading [data-media-type=image] img":
            "onShowFullSizeImage",
          "click  .panel-reading [data-media-type=image] img": "stopEvent",
          "tap    .panel-reading [data-media-type=image] .image":
            "onShowFullSizeImage",
          "click  .panel-reading [data-media-type=image] .image": "stopEvent",
          "tap .on-full-size-banner": "onFullSizeBanner",
          "click .on-full-size-banner": "stopEvent",
          "tap   .on-comments": "onComments",
          "click .on-comments": "stopEvent"
        },
        app.views.CoreStoryReading.prototype.events
      ),

      initialize: function(options) {
        if (options.commentId) {
          this.commentId = options.commentId;
        }

        app.views.CoreStoryReading.prototype.initialize.apply(this, arguments);
      },

      render: function() {
        app.views.CoreStoryReading.prototype.render.apply(this, arguments);
        var self = this,
          data = this.model.toJSON();
        data.text = this.options.storyText;
        data.firstPage = data.pageNumber == 1;
        data.lastPage = data.pageNumber >= data.pages;
        data.nextPage =
          data.pageNumber < data.pages
            ? data.url.replace(
                /\.(com|io)\/(.*)/,
                ".$1/$2/page/" + (data.pageNumber + 1)
              )
            : "";
        data.isBigPart = self.getIsBigPart();
        data.group.category = _.find(data.group.categories, function(category) {
          return category > 0;
        });
        data.isMicroPart = data.pages < 2;

        if (data.group.isPaywalled) {
          const { paidMetadata } = window.store.getState();
          this.processPaidMetadata(data, paidMetadata);
          data.onBuyCoins = () => this.onBuyCoins(data.blockedPart);
        }

        var mainCategory;
        if (data.group.category) {
          mainCategory = app
            .get("categories")
            .get(data.group.category)
            .get("name");
        }

        var device = app.get("device");

        var meta = {
          group: {
            id: data.group.id,
            title: data.group.title,
            cover: data.group.cover,
            category: mainCategory,
            description: data.group.description,
            tags: _.map(data.group.tags, function(tag) {
              return { name: tag };
            }).concat([{ name: mainCategory }])
          },
          part: {
            id: data.id,
            title: data.title,
            url: data.url
          },
          user: {
            twitter: data.group.user.twitter
          }
        };

        var currentIndex = _.findIndex(data.group.parts, { url: data.url });
        if (currentIndex < data.group.parts.length - 1) {
          data.nextPart = data.group.parts[currentIndex + 1];
        }

        //set cookie for signupForm
        wattpad.utils.setCookie(
          "signupFrom",
          "story_reading",
          0,
          window.location.hostname.replace("www", "")
        );

        //send event for reading time tracking
        var publishedParts = utils.publishedPartsInStory(data.group);
        window.te.push("event", "reading", null, null, "start", {
          storyid: data.group.id,
          partid: data.id,
          published_parts: publishedParts.length
        });

        // Don't show the sharing links if the part isn't published
        if (!data.draft) {
          var socialLinks = this.buildSocialShareLinksForPart(meta);
          var mediaShare = [];
          mediaShare.push(socialLinks[0]);
          mediaShare.push(socialLinks[2]);

          this.mediaShare = mediaShare;

          data.socialShareHidden = socialLinks.splice(4, 2);
          data.socialShareVisible = socialLinks;
        }

        data.anonymousUser = !wattpad.utils.currentUser().authenticated();
        data.descCharLimit = device.is.mobile ? 130 : 170;

        _.defer(function() {
          var device = app.get("device");

          if (!self.model.get("deleted")) {
            if (wattpad.utils.currentUser().authenticated()) {
              var promotedStoryView = new app.views.PromotedStoryItem({
                storyId: self.model.get("group").id,
                baseStoryCategory: self.model.get("group").categories[0],
                zoneIds: [170121],
                fromPage: "storyReading",
                promotedEl: ".promoted-recommended-stories"
              });

              self.setRegion(
                ".promoted-recommended-stories-view",
                promotedStoryView.render()
              );

              var recommendedStoryCollection = new app.collections.RecommendedStories(
                [],
                {
                  username: wattpad.utils.currentUser().get("username"),
                  limit: 8
                }
              );

              Promise.resolve(recommendedStoryCollection.fetchNextSet()).then(
                function() {
                  var recommendedStoryItems = {
                    items: {
                      stories: recommendedStoryCollection.toJSON()
                    }
                  };
                  var recommendedStoriesModel = new Monaco.Model();
                  recommendedStoriesModel.set("data", recommendedStoryItems);
                  recommendedStoriesModel.set(
                    "type",
                    "storiesReadingRecommended"
                  );

                  var recommendedStories = new app.views.DiscoverModule({
                    model: recommendedStoriesModel,
                    experience: "story_reading"
                  });
                  recommendedStories.setElement($(".recommended-stories-view"));
                  recommendedStories.render();
                }
              );
            }
          }

          //WEB-5616 Story Reading Similar Stories
          //By default this is hidden and blur text is still showing up
          //Optimizely will show this section and hide blur text
          //We need to clear it as we registered empty partials in the DOM
          if (!self.model.get("deleted") && data.anonymousUser) {
            var promotedStoryView = new app.views.PromotedStoryItem({
              storyId: self.model.get("group").id,
              baseStoryCategory: self.model.get("group").categories[0],
              zoneIds: [170121],
              template: storiesSimilar,
              fromPage: "storyReading",
              descCharLimit: data.descCharLimit,
              promotedEl: ".promoted-stories-container"
            });

            self.setRegion(".promoted-stories", promotedStoryView.render());

            self.$(".similar-stories").empty();

            var view = new app.views.StoriesSimilar({
              filter: "similar",
              storyId: self.model.get("group").id,
              limit: 3,
              currentStory: self.model,
              fromStoryReading: true,
              alwaysFetch: true,
              showDescription: true
            });

            self.setRegion(".similar-stories", view.render());

            self.$(".similar-stories-container").removeClass("hidden");
          }
        });

        data.isDesktop = this.isDesktop; // why would this ever be true in a mobile file?
        data.isMobile = device.is.mobile;

        this.$el.html(this.template(data));

        this.setRegions(data.currentPartIsBlocked);

        return this;
      },

      onComments: function() {
        const footerActionsTop = this.$("#part-footer-actions")?.offset()?.top;
        const pages = this.model.get("pages");
        if (pages <= 1) {
          $("html, body").animate({ scrollTop: footerActionsTop }, 1000);
        } else {
          // Animation doesn't work with incremental loading, so instantly scroll
          window.scrollTo(window.scrollX, footerActionsTop);
        }
      },

      // Override DW isBigPart setting on MW pages
      getIsBigPart: function() {
        return this.model.get("pages") >= 3;
      },

      onAddToListsReady: function(element, storyId) {
        this.$(".addToLibraryModal[data-story-id='" + storyId + "']").modal({
          show: true
        });
      },

      buildSocialShareLinksForPart: function(meta) {
        return [
          {
            name: "post-to-profile",
            icon: "fa-user",
            nopopup: true,
            label: wattpad.utils.trans("Post to Your Profile"),
            href: "/"
          }
        ];
      }
    })
  );

  app.mixin(
    app.views.StoryReading,
    "SocialSharing",
    "VoteManagement",
    "LibraryManagement",
    "StoryInListsManagement",
    "AuthPromptManagement",
    "FacebookConnect"
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);

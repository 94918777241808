var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.components.add_to_library_modal"],depth0,{"name":"core.components.add_to_library_modal","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = 
  "<div id=\"funbar-container\" class=\"row\">\n    <div id=\"funbar\">\n"
    + ((stack1 = container.invokePartial(partials["core.story_reading.funbar"],depth0,{"name":"core.story_reading.funbar","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<main id=\"parts-container-new\">\n"
    + ((stack1 = container.invokePartial(partials["core.story_reading.part_container_new"],depth0,{"name":"core.story_reading.part_container_new","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</main>\n\n<div id=\"inline-comments-modal\"></div>\n\n";
  stack1 = ((helper = (helper = helpers.group || (depth0 != null ? depth0.group : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"group","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.group) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
import mobileSearchField from "platform/shared/templates/mobile-search-field.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "SearchField",
    app.views.SearchFieldBase.extend({
      className: "search-field-view",

      template: mobileSearchField,

      events: _.extend(
        {
          "tap .on-search": "onSearchSelected",
          "click .on-search": "stopEvent",
          "submit #search-form": "onSearch",
          "search #search-query": "onSearch", // for iOS keyboard
          "input #search-query": "onSearchInput",
          "touchmove .dropdown-menu": "onScrollSearchSuggestions" // for iOS
        },
        app.views.SearchFieldBase.prototype.events
      ),

      initialize: function(options) {
        options = options || {};
        this.mode = options.mode || "stories";
        this.query = options.query || "";
        this.isOnSearch = options.isOnSearch || false;
        this.targetSelector = "#search-query";

        this.listenTo(app, "navbar:search:set", this.set);
        app.views.SearchFieldBase.prototype.initialize.apply(this, arguments);
      },

      getData: function() {
        return {
          searchUrl: this.getSearchUrl(this.mode),
          query: this.query,
          isOnSearch: this.isOnSearch
        };
      },

      render: function() {
        this.$el.html(this.template(this.getData()));
        return this;
      },

      renderAutocomplete: function(results) {
        this.$("ul.autocomplete-item-container").html(
          this.autocompleteTemplate({ results: results })
        );
      },

      onSearchSelected: function(evt) {
        var query;
        if (!this.isOnSearch) {
          // let search-view handle it if its on search
          wattpad.utils.stopEvent(evt); // stop the submit event
          query = this.$("#search-query")
            .val()
            .trim();
          if (query.length > 0) {
            this.onSearch(evt);
          } else {
            this.$("#search-query").focus();
          }
        }
      },

      onSearch: function(evt) {
        var query = this.$("#search-query")
          .val()
          .trim();
        this.$("#search-query").blur();

        this.sendSearchTracking(query);
        this.hideDropdownResults();

        if (!this.isOnSearch && query.length > 0) {
          // let search-view handle it if its on search
          wattpad.utils.stopEvent(evt);
          app.router.navigate(
            this.getSearchUrl(this.mode) + encodeURIComponent(query),
            { trigger: true }
          );
        } else if (query.length > 0) {
          app.trigger("navbar:search:query", query);
        }
      },

      onSearchInput: function(evt) {
        // For WP8 keyboard
        if (evt.which === 13) {
          // enter
          this.onSearch(evt);
        } else {
          var searchTerm = $(evt.currentTarget)
            .val()
            .trim();

          if (!this.isOnSearch && searchTerm.length >= 2) {
            this.showAutocompleteSuggestions(searchTerm);
          } else {
            // Update search results
            app.trigger("navbar:search:query", searchTerm);
          }
        }
      },

      onScrollSearchSuggestions: function(evt) {
        // Hack; Wasn't able to disable background scrolling for iOS devices
        // Disable scrolling search suggestions but they can still scroll the
        // background if they start on the nav bar
        if (app.get("device").is.ios) {
          evt.preventDefault();
        }
      },

      showAutocompleteSuggestions: _.throttle(function(searchTerm) {
        var collection = new app.collections.Autocomplete([], {
            searchTerm: searchTerm
          }),
          self = this;

        Promise.resolve(collection.fetch()).then(function() {
          if (collection.length) {
            self.renderAutocomplete(collection.toJSON());
            self.showDropdownResults();
          }
        });
      }, 500),

      showDropdownResults: function() {
        if (!this.$("#search-form").hasClass("open")) {
          this.$("#search-form").addClass("open");
          $("body").addClass("modal-open"); // Disable background scrolling
          app.trigger("navbar:search-results:show");
        }
      },

      hideDropdownResults: function() {
        this.$("#search-form").removeClass("open");
        $("body").removeClass("modal-open"); // Enable background scrolling
        app.trigger("navbar:search-results:hide");
      },

      onAutocompleteItem: function() {
        this.hideDropdownResults();
        app.views.SearchFieldBase.prototype.onAutocompleteItem.apply(
          this,
          arguments
        );
      },

      set: function(term, update) {
        var $search = this.$("#search-query");
        $search.val(term);

        if (update !== false) {
          app.trigger("navbar:search:query", term);
        }
      }
    })
  );
})(window, _, wattpad, window.app);

var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"col-xs-6 button\">\n  <a class=\"on-signup btn btn-orange btn-block\" rel=\"nofollow\" href=\"/signup\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Sign Up",{"name":"trans","hash":{},"data":data}))
    + "</a>\n</div>\n<div class=\"col-xs-6 button\">\n  <a class=\"on-install btn btn-grey btn-block\" data-gtm=\"Download|banner_mw\" href=\""
    + alias3(((helper = (helper = helpers.downloadAppUrl || (depth0 != null ? depth0.downloadAppUrl : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"downloadAppUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Download App",{"name":"trans","hash":{},"data":data}))
    + "</a>\n</div>\n";
},"useData":true});
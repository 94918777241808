(function(window, _, $, wattpad, app) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});

  app.router.on(
    "route:notifications",
    app.router.filter(["getTranslations", "requireLogin"], function() {
      var view = new app.views.NotificationsPage();
      app.transitionTo(view, {
        hasHeader: true,
        hasFooter: true,
        pageTitle: "Wattpad - Notifications"
      });

      window.te.push("event", "app", "page", null, "view", {
        page: "notifications_feed"
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);

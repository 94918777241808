import themeSettings from "core/templates/profile/theme-settings.hbs";
(function(window, _, wattpad, app, utils) {
  "use strict";

  app.add(
    "ThemeSettings",
    app.views.Base.extend({
      template: themeSettings,

      showSubscriptionModal: function() {
        if ($("#subscription-modal").length) {
          var subscriptionModal = new app.views.SubscriptionModal({
            model: this.model
          });
          this.$("#subscription-modal")
            .empty()
            .append(subscriptionModal.render().$el);
          subscriptionModal.showModal();
        }
      },

      render: function() {
        var templateData = {
          // Allow React theme settings component to show the subscription modal
          showSubscriptionModal: this.showSubscriptionModal.bind(this),
          isPremium: wattpad.utils.currentUser().get("isPremium")
        };

        this.$el.html(this.template(templateData));
        return this;
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);

import mobileFooter from "platform/shared/templates/mobile-footer.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "Footer",
    Monaco.View.extend({
      template: mobileFooter,

      events: {
        // links
        "click .on-language": "stopEvent",
        "tap .on-language": "onLanguageSelected",

        "tap .on-view-full-site": "onViewFullSite",
        "click .on-view-full-site": "onViewFullSite"
      },

      initialize: function(options) {
        this.setOptions(options);
      },

      setOptions: function(options) {
        options = options || {};
      },

      render: function() {
        var device = app.get("device");
        this.$el.html(
          this.template({
            // open in wattpad app
            showOpenApp:
              this.openAppUrl !== "" && (device.is.ios || device.is.android)
                ? true
                : false,
            openAppUrl: this.openAppUrl,

            // view full site
            desktopUrl: wattpad.utils.getDesktopUrl(),

            // download app
            downloadAppUrl: wattpad.utils.getAppUrl(),
            copyright: wattpad.utils.trans(
              "Copyright %s - Wattpad",
              new Date().getFullYear()
            )
          })
        );

        return this;
      },

      reset: function() {
        this.render();
      },

      onViewFullSite: function(evt) {
        wattpad.utils.stopEvent(evt);
        wattpad.utils.openOnDesktop();
      },

      onLanguageSelected: function(evt) {
        var $iframe = $("#languageModal iframe");

        $iframe.attr("src", "/settings/language?jq=true");
        $iframe[0].onload = function() {
          $iframe[0].contentWindow.callbackFn = function(language) {
            var search = wattpad.utils.getWindowSearch() || "?";
            $("#languageModal").modal("hide");

            if (language) {
              if (search.indexOf("language") > -1) {
                search = search.replace(
                  /language=[^\&]*/,
                  "language=" + language
                );
              } else {
                search += "&language=" + language;
              }

              wattpad.utils.reloadWithQuery(search.replace(/\?\&/, "?"));
            }
          };
        };

        $("#languageModal")
          .modal({ show: true })
          .removeClass("hide");
      }
    })
  );

  app.mixin(app.views.Footer, "OpenInApp");
})(window, _, wattpad, window.app);

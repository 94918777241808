(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "SectionTransition",
    app.transitions.DefaultTransition.extend({
      start: function(fromView, toView, options) {
        // by default result is going to be the toView
        var resultView = toView;

        // if the parent view is not the current app view then transition to it
        if (!fromView || fromView.cid !== toView.cid) {
          resultView = app.transtions.DefaultTransition.prototype.start.apply(
            this,
            arguments
          );
        }

        return resultView;
      }
    })
  );
})(window, Monaco, wattpad, window.app);

import mobileDownloadInterstitial from "platform/shared/templates/mobile-download-interstitial.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "DownloadInterstitial",
    app.views.Base.extend({
      template: mobileDownloadInterstitial,
      events: {
        // links
        "tap #skip": "close",
        "click #skip": "stopEvent"
      },

      render: function() {
        this.$el.html(this.template({ platform: this.getPlatform() }));
        $("html,body")
          .addClass("stopScroll")
          .scrollTop(0);
        return this;
      },

      setOptions: function(options) {
        this.appUrlMedium = "appinterrupt_mw";
      },

      reset: function() {
        this.render();
      },

      close: function(evt) {
        wattpad.utils.stopEvent(evt);
        this.$el.html("");
        $("html,body").removeClass("stopScroll");
      },

      getPlatform: function() {
        var device = app.get("device");
        if (device && device.is.ios) {
          return "iOS";
        }
        if (device && device.is.android) {
          return "Android";
        }
        return "other";
      }
    })
  );

  app.mixin(app.views.DownloadInterstitial, "OpenInApp");
})(window, _, wattpad, window.app);

import settingsModal from "platform/profile/templates/settings-modal.hbs";
import userProfile from "platform/profile/templates/user-profile.hbs";

import userBadges from "core/templates/components/user-badges.hbs";
import { MODAL_ID } from "../../../../../components/shared-components";

(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Top level View for the Mobile-Web User Profile page
   * Controls a 'User Card', displaying a user's basic information, and a set of navigable sub-sections
   * @Class UserProfile
   * @constructor
   */
  app.add(
    "UserProfile",
    app.views.RegionManager.extend({
      // Properties
      // ----------
      className: "user-profile container",
      template: userProfile,

      events: {
        // links
        "tap   .on-nav-item": "onSectionNavigate",
        "click .on-nav-item": "stopEvent",

        // Public profile follow/unfollow - also see FollowManager mixin
        "tap   .on-follow-user": "onFollowUser",
        "click .on-follow-user": "stopEvent",

        // Follow/Unfollow, Following Request, and Settings buttons
        "tap .on-following-request": "onFollowingRequest",
        "tap .on-settings": "showSettingsModal",

        // Settings modal
        "tap #settings-modal #on-logout": "onLogout",

        "touchstart .on-nav-item": "setTouchState",
        "touchstart .on-settings": "setTouchState",
        "touchstart .on-follow-user": "setTouchState",
        "touchstart .on-following-request": "setTouchState",

        "touchend .on-nav-item": "removeTouchState",
        "touchend .on-settings": "removeTouchState",
        "touchend .on-follow-user": "removeTouchState",
        "touchend .on-following-request": "removeTouchState",

        "tap   .scroll-to-element": "scrollToElement",
        "click .scroll-to-element": "stopEvent",

        // Open Followers modal
        "tap   .on-followers": "showFollowersModal",
        "click .on-followers": "stopEvent",

        "tap   .on-view-conversations": "viewAllConversations",
        "click .on-view-conversations": "stopEvent",

        "tap    .on-mute": "onMute",
        "keypress .on-mute": "onMute",
        "touchstart .on-mute": "onMute",

        "click .on-mute": "stopEvent",

        "tap    .on-block": "onBlock",
        "keypress .on-block": "onBlock",
        "touchend .on-block": "onBlock",

        "click .on-block": "stopEvent",

        "tap    .on-unmute": "onUnmute",
        "keypress .on-unmute": "onUnmute",
        "touchend .on-unmute": "onUnmute",

        "click .on-unmute": "stopEvent"
      },

      /**
       * Section map for subview navigation via {@link setSection}
       * @namespace sections
       * @properties about | conversations
       */
      sections: {
        about: {
          View: app.views.ProfileAbout
        },
        conversations: {
          View: app.views.ProfileConversations,
          Collection: app.collections.ActivityMessages
        }
      },

      reportModalContainer: "#profile-modal-container",
      modalTitle: wattpad.utils.trans("Report a User"),
      requestType: "reported_users",
      isMainReportModal: true,

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};
        this.section = options.section;
        this.sectionView = null;
        this.isPrivateToMe = options.isPrivateToMe;
        this.username = this.model.get("username");
        this.recentlyUnmuted = options.recentlyUnmuted;
        this.recentlyUnblocked = options.recentlyUnblocked;

        //required data for report modal
        this.reportModalTitle = wattpad.utils.trans("Report a User");
        this.reportModalType = "user";
        this.reportModalId = {
          name: options.username,
          location: window.location.href
        };

        this.listenTo(app, "app:user:follow", this.updateToFollowingButton);
        this.listenTo(app, "app:user:unfollow", this.updateToFollowButton);

        Handlebars.registerPartial("core.components.user_badges", userBadges);
      },

      render: function() {
        var data = this.model.toJSON(),
          self = this;

        // WRITER-871: staff badge takes precedence over ambassador and verified badges
        var isStaff = this.model.isStaff();
        if (isStaff) {
          data.ambassador = false;
          data.verified = false;
        }
        // show Mute or Unmute option if user is logged in
        this.showMuteOption =
          wattpad.utils.currentUser().get("username") &&
          this.username !== wattpad.utils.currentUser().get("username");

        let isMuted = this.model?.get("safety")?.isMuted;
        let isBlocked = this.model?.get("safety")?.isBlocked;

        this.$el.html(
          this.template(
            _.extend(data, {
              isPrivateToMe: this.isPrivateToMe,
              staff: isStaff,
              followingRequested: this.model.get("followingRequest") === "req",
              followerRequested:
                this.model.get("followerRequest") === "req" ||
                this.model.get("followerRequest") === "ign",
              followerIgnored: this.model.get("followerRequest") === "ign",
              testGroups: wattpad.testGroups,
              username: this.username,
              showMuteOption: this.showMuteOption,
              isMuted: isMuted,
              isBlocked: isBlocked,
              recentlyUnmuted: this.recentlyUnmuted,
              recentlyUnblocked: this.recentlyUnblocked,
              onWalletClick: () => {
                const buyCoinsModal = utils.getModal(MODAL_ID.BUY_COINS_MODAL);
                buyCoinsModal.setData({
                  page: "profile",
                  device: app.get("device"),
                  appUrl: utils.getAppUrl()
                });

                buyCoinsModal.show();
              }
            })
          )
        );

        if (this.recentlyUnmuted) {
          const toastProps = {
            className: "unmute",
            body: wattpad.utils.trans("%s is now unmuted. You can mute them at any time.", this.username) //prettier-ignore
          };
          // Trigger this asynchronously.
          setTimeout(() => {
            window.store.dispatch(
              window.app.components.actions.toggleToast(toastProps)
            );
          }, 0);
        }
        if (this.recentlyUnblocked) {
          const toastProps = {
            className: "unblock",
            body: wattpad.utils.trans("%s is now unblocked. You can block them at any time.", this.username) //prettier-ignore
          };
          // Trigger this asynchronously.
          setTimeout(() => {
            window.store.dispatch(
              window.app.components.actions.toggleToast(toastProps)
            );
          }, 0);
        }

        // set cookie for signup form
        wattpad.utils.setCookie(
          "signupFrom",
          "user_profile",
          0,
          window.location.hostname.replace("www", "")
        );

        this.$("li." + this.section + " a").addClass("selected");

        this.setSection(this.section);

        _.defer(function() {
          if (self.section === "followers") {
            self.isFollowersSection = true;
            self.showFollowersModal();
          }
        });

        return this;
      },

      setElement: function(el) {
        var $el = $(el);
        //Call through to parent
        app.views.RegionManager.prototype.setElement.call(this, el);

        // Attach child views
        if (this.sectionView) {
          var selectorString = "";
          var selector = this.sectionView.className.trim().split(" ");
          _.each(selector, function(text) {
            selectorString += "." + text;
          });
          this.sectionView.setElement($el.find(selectorString.trim()));
        }
      },

      /**
       * generates a `view` for the specified section (mapped via {@link sections}) and injects it into the main content region
       * @param section { string }    section map key
       */
      setSection: function(section) {
        var collection, $numFollowing;

        this.section = section;

        // The about section is a simple view displaying data already loaded in `this.model`
        if (
          section === "about" ||
          section === "followers" ||
          section === "following"
        ) {
          this.sectionView = new this.sections["about"].View({
            model: this.model,
            isPrivateToMe: this.isPrivateToMe,
            section: this.section,
            setTitle: this.setTitle
          });
          this.clearRegion("#profile-content");
          this.setRegion("#profile-content", this.sectionView.render());
        } else if (section === "quests") {
          setTimeout(() => {
            // This component fails to mount on the server-side when running synchronously.
            // We're running this asynchronously to prevent an error log to show in the console.
            this.sectionView = new app.views.DummyReactView({
              component: "Quests",
              componentId: "quests",
              componentData: this.model
            });
            this.clearRegion("#profile-content");
            this.setRegion("#profile-content", this.sectionView.render());
          }, 0);
        } else {
          collection = new this.sections[section].Collection([], {
            username: this.username
          });
          this.sectionView = new this.sections[section].View({
            model: this.model,
            collection: collection,
            isMessage: this.sections[section].isMessage
          });

          switch (section) {
            case "following":
              if (
                this.model.get("username") ===
                utils.currentUser().get("username")
              ) {
                $numFollowing = this.$("#profile-card")
                  .find('a[data-section="following"]')
                  .children("span");
                collection.on("remove", function() {
                  $numFollowing.html(parseInt($numFollowing.html()) - 1);
                });
              }
              this._fetchAndRenderSection();
              break;
            default:
              this._fetchAndRenderSection();
              break;
          }
        }

        this.setTitle(section);
      },

      setTitle: function(section) {
        var profileTitle;
        switch (section) {
          case "following":
            profileTitle = wattpad.utils.trans(
              "People followed by %s",
              this.model.get("username")
            );
            break;

          case "followers":
            profileTitle = wattpad.utils.trans(
              "People following %s",
              this.model.get("username")
            );
            break;

          default:
            profileTitle =
              this.model.has("name") && this.model.get("name") !== ""
                ? this.model.get("name") +
                  " (" +
                  this.model.get("username") +
                  ")"
                : this.model.get("username");
            if (this.model.get("username").length % 2 === 1) {
              profileTitle += " | " + wattpad.utils.trans("Where stories live");
            }
        }
        wattpad.utils.setTitle(profileTitle);
      },

      _fetchAndRenderSection: function(data) {
        var self = this;
        $.when(this.sectionView.collection.fetchNextSet(data || {})).done(
          function() {
            self.clearRegion("#profile-content");
            self.setRegion("#profile-content", self.sectionView.render());
          }
        );
      },

      // has to be implemented as a function because of dynamic data
      // please keep consistent with dw profile-layout.js
      reportMoreInfo: function() {
        return [{ key: "Reported User", val: window.location.href }];
      },

      onMuteHelper: function(modalType) {
        var view = new app.views.MuteModal({
          modalType: modalType,
          username: this.username,
          showMutedProfile: true
        });

        $("#generic-modal .modal-body").html(view.render().$el);
        $("#generic-modal .modal-dialog").addClass("mute-modal-dialog");
        $("#generic-modal .modal-content").addClass(
          "mute-modal-wrapper mute-modal"
        );
        $("#generic-modal").modal({});

        this.listenTo(view, `${modalType}:done`, this.render);
        // if we don't clear the cache, the updated isMuted attribute
        // won't be immediately reflected in the model
        app.local.clear("user." + this.username);
      },

      onMute: function() {
        this.onMuteHelper("mute");
      },

      onUnmute: function() {
        this.onMuteHelper("unmute");
      },

      onBlockHelper: function(modalType) {
        var view = new app.views.BlockModal({
          modalType: modalType,
          username: this.username,
          showRestrictedProfile: true
        });

        $("#generic-modal .modal-body").html(view.render().$el);
        $("#generic-modal .modal-dialog").addClass("block-modal-dialog");
        $("#generic-modal .modal-content").addClass(
          "block-modal-wrapper block-modal"
        );
        $("#generic-modal").modal({});

        this.listenTo(view, `${modalType}:done`, this.render);
        // if we don't clear the cache, the updated isMuted attribute
        // won't be immediately reflected in the model
        app.local.clear("user." + this.username);
      },

      onBlock: function() {
        this.onBlockHelper("block");
      },

      onUnblock: function() {
        this.onBlockHelper("unblock");
      },
      // Called immediately after button press
      onFollowUser: function(evt) {
        if (utils.currentUser().authenticated()) {
          var numFollowers = this.model.get("numFollowers");

          // 'following' state has not been updated yet
          // i.e. you were following the user and now you want to unfollow
          numFollowers = this.model.get("following")
            ? numFollowers - 1
            : numFollowers + 1;

          this.$(".followers-count").text(utils.count(numFollowers));
          this.model.set("numFollowers", numFollowers);
        }
      },

      // Event triggered by FollowManager mixin
      updateToFollowingButton: function(username) {
        if (this.model.get("username") === username) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-white").addClass("btn-teal");
          $btn
            .find(".fa")
            .removeClass("fa-follow fa-wp-base-2-dark")
            .addClass("fa-following fa-wp-neutral-5");
          $btn.find(".truncate").text(utils.trans("Following"));

          this.model.set("following", true);
        }
      },

      // Event triggered by FollowManager mixin
      updateToFollowButton: function(username) {
        if (this.model.get("username") === username) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-teal").addClass("btn-white");
          $btn
            .find(".fa")
            .removeClass("fa-following fa-wp-neutral-5")
            .addClass("fa-follow fa-wp-base-2-dark");
          $btn.find(".truncate").text(utils.trans("Follow"));

          this.model.set("following", false);
        }
      },

      onFollowingRequest: function(evt) {
        var self = this,
          $btn = this.$(evt.currentTarget),
          requested = this.model.get("followingRequest") === "req",
          action = requested ? "unfollow" : "follow",
          newReqState = requested ? "" : "req";

        if (utils.currentUser().authenticated()) {
          // Update Request/Requested buttons
          if (requested) {
            $btn
              .addClass("btn-white")
              .removeClass("btn-teal")
              .html(
                utils.iconify("fa-follow", 18, "wp-base-2") +
                  " " +
                  utils.trans("Request")
              );
          } else {
            $btn
              .addClass("btn-teal")
              .removeClass("btn-white")
              .html(
                utils.iconify("fa-following-request", 18, "wp-neutral-5") +
                  " " +
                  utils.trans("Requested")
              );
          }

          Promise.resolve(
            wattpad.utils
              .currentUser()
              .following()
              [action](this.model)
          ).then(function() {
            self.model.set("followingRequest", newReqState);
          });
          // Display the sign up modal
        } else {
          this.onAuthPrompt(evt);
        }
      },

      showSettingsModal: function() {
        var modalTemplate = settingsModal;
        this.$("#settings-modal").html(modalTemplate());
        this.$("#settings-modal").modal("show");
      },

      onLogout: function(evt, win) {
        utils.stopEvent(evt);

        var currentUser = utils.currentUser(),
          currentUserName = currentUser.get("username");

        win = win || window;

        if (currentUserName) {
          win.localStorage.clear();
          utils.redirectToServer("/logout?nexturl=" + win.location.href);
        }
      },

      onSectionNavigate: function(evt) {
        utils.stopEvent(evt);

        var $target = $(evt.currentTarget),
          targetSection = $target.data("section");

        if (targetSection === this.section) {
        }
        if (targetSection === "about") {
          this.$("#profile-nav li.about a").addClass("selected");
        } else {
          if (
            !wattpad.utils.currentUser().authenticated() &&
            targetSection === "conversations"
          ) {
            this.onAuthPrompt(evt);
          } else {
            $target.addClass("selected");
          }
        }

        this.$("#profile-nav li a").removeClass("selected");

        this.shouldNotScrollToTop = true;

        app.router.navigate(
          "/user/" + this.username + "/" + $target.data("section"),
          {
            trigger: true,
            replace: true
          }
        );
      },

      setTouchState: function(evt) {
        $(evt.currentTarget).addClass("touched");
      },

      removeTouchState: function(evt) {
        $(evt.currentTarget).removeClass("touched");
      },

      getDeepLinkPage: function() {
        return "user-profile";
      },

      scrollToElement: function(evt) {
        var fireTriggerNow = true;

        // Switch to the About tab, if not already there
        if (this.section !== "about") {
          this.setSection("about");
          app.router.navigate("/user/" + this.username, { replace: true });
          // Highlight About tab
          this.$("#profile-nav li a").removeClass("selected");
          this.$("#profile-nav li.about a").addClass("selected");

          fireTriggerNow = false;
        }

        this.sectionView.setScrollToElementId(
          $(evt.currentTarget).data("id"),
          fireTriggerNow
        );
      },

      showFollowersModal: function(evt) {
        var self = this;
        var collection = new app.collections.ProfileFollowers([], {
          username: this.username,
          limit: 10
        });

        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthPrompt(evt);
        } else {
          Promise.resolve(collection.fetchNextSet()).then(function() {
            var view = new app.views.ProfileFollowersFollowingModal({
              model: self.model,
              collection: collection
            });

            self.previousSection =
              self.section === "followers" ? "" : self.section;
            self.setTitle("followers");
            self
              .$("#follower-modal")
              .empty()
              .append(view.render().$el);
            view.showModal();

            self.$("#follower-modal").on("hidden.bs.modal", function() {
              if (self.isFollowersSection) {
                self.isFollowersSection = false;
                var section =
                  self.previousSection === "about"
                    ? ""
                    : "/" + self.previousSection;
                app.router.navigate("/user/" + self.username + section, {
                  replace: true
                });
              }
              self.setTitle(self.previousSection);
              view.remove();
            });
          });
        }
      },

      viewAllConversations: function(evt) {
        utils.stopEvent(evt);

        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthPrompt(evt);
          $(".modal-backdrop").addClass("profile-show");
        } else {
          window.location = $(evt.currentTarget).attr("href");
        }
      }
    })
  );

  app.mixin(
    app.views.UserProfile,
    "ReportManagement",
    "ReportConduct",
    "AuthPromptManagement",
    "FollowManager"
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);

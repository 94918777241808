(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:contentPreferences",
    app.router.filter(["getTranslations"], function() {
      var contentPreferencesView = new app.views.DummyReactView({
        component: "ContentPreferences",
        componentId: "content-preferences",
        componentData: {
          trans: wattpad.utils.trans
        }
      });

      app.transitionTo(contentPreferencesView, {
        hasHeader: true,
        hasFooter: false
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);

import userItem from "platform/shared/templates/item/user-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * User ItemView - small user card displaying general meta info & follow/unfollow button
   * @Class UserItem    View
   * @constructor
   */
  app.add(
    "UserItem",
    Monaco.View.extend({
      // Properties
      // ----------
      className: "user-item clearfix",
      template: userItem,

      events: {
        // links
        "click .on-user-item": "onUserItem",
        "tap .on-user-item": "onUserItem",

        // action
        "tap .on-follow-user": "onFollowUser",
        "tap .on-dismiss": "onRemoveFollower" // stay consistent with recommended-follow-item
      },

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};
        this.showRemove = options.showRemoveFollower;
      },

      render: function() {
        var data = _.extend(this.model.toJSON(), {
          showRemove: this.showRemove
        });
        this.$el.html(this.template(data));
        return this;
      },

      // Event Handlers
      onFollowUser: function(evt) {
        utils.stopEvent(evt);

        var profileUserName = this.model.collection.username,
          currentUser = utils.currentUser(),
          currentUserName = currentUser.get("username"),
          following = this.model.get("following"),
          apiMethod = following ? "unfollow" : "follow";

        // update UI elements
        if (following) {
          this.$(".on-follow-user")
            .removeClass("following")
            .html(wattpad.utils.iconify("fa-follow", 22, "wp-base-2"));
        } else {
          this.$(".on-follow-user")
            .addClass("following")
            .html(wattpad.utils.iconify("fa-following", 22, "wp-neutral-5"));
        }

        // toggle following state
        this.model.set("following", !following);

        // POST follow/unfollow request to the API
        $.when(currentUser.following()[apiMethod](this.model)).done(function() {
          // clear cached data as necessary
          app.local.clear("user." + currentUserName);
          app.local.clear("user." + currentUserName + ".following");
          app.local.clear("user." + currentUserName + ".profile.following");

          app.local.clear("user." + profileUserName);
          app.local.clear("user." + profileUserName + ".profile.following");
          app.local.clear("user." + profileUserName + ".profile.followers");
        });
      },

      onRemoveFollower: function() {
        var self = this;

        this.model.deleteFollower().then(function() {
          if (self.model.collection) {
            self.model.collection.remove(self.model);
          }
        });
      },

      onUserItem: function(evt) {
        utils.stopEvent(evt);

        app.router.navigate("/user/" + this.model.get("username"), {
          trigger: true
        });
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);

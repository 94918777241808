(function(window, _, $, wattpad, app) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});

  app.router.on(
    "route:themeSettings",
    app.router.filter(["getTranslations", "requireLogin"], function(username) {
      var model = new app.models.User({ username: username });

      $.when(model.loaded())
        .done(function() {
          var themeSettingsView = new app.views.ThemeSettings({
            model: model
          });

          app.transitionTo(themeSettingsView, {
            hasHeader: true,
            hasFooter: true
          });

          window.te.push("event", "app", "page", null, "view", {
            page: "theme"
          });
        })
        .fail(function(err) {
          app.router.trigger("route:error-404", "theme");
        });
    })
  );
})(window, _, jQuery, wattpad, window.app);

(function(window, _, $, wattpad, app) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});

  app.router.on(
    "route:userMenu",
    app.router.filter(["getTranslations", "requireLogin"], function() {
      var userMenuView = new app.views.UserMenu();

      app.transitionTo(userMenuView, {
        hasHeader: true,
        hasFooter: true
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
